import { z } from 'zod';

import { TextBoxControlLayout, FormLayout } from 'components/Form/Layout';

function hasMax(control: any): control is TextBoxControlLayout {
	const textbox = control as TextBoxControlLayout;

	return textbox.max !== undefined;
}

export function buildSchemaFromLayout(layout: FormLayout) {
	const { controls } = layout;

	if (controls) {
		const scheme: { [key: string]: any } = {};

		for (const [name, control] of Object.entries(controls)) {
			scheme[name] = z.string();

			if (hasMax(control)) {
				scheme[name] = scheme[name].max(control.max);
			}

			if (control.required) {
				scheme[name] = scheme[name].nonempty('Cannot be empty');
			}
		}

		return z.object(scheme);
	}

	return null;
}
