const Constants = {
	// max number of items in a single batch for perf reason
	maxItemsInBatch: 50,
	// default server response records length
	serverPageSize: 50,
	// Odata needs it for getting all results
	maxPageSize: 102400,
	// used for joining batch requests
	lineBreak: '\r\n',
	// odata native params
	nativeParams: ['select', 'filter', 'orderby', 'skip', 'top', 'inlinecount'],
	// Used as a value for 'top' parameter when precreating
	precreateTopValue: '-1',
	// clearing Int64 is not easy in Odata, you have to be a real magician
	emptyInt64: '-9223372036854775808',
	emptyDecimal: '-79228162514264337593543950335',
	emptyDatetime: '0001-01-01T00:00:00',
	// set of regexps used to parse Batch responses
	regexps: {
		property: /\w+(\/*\w+){0,}/g, // we use that for extracting field names from a string, i.e. from 'Name/NameCode, Name/Name1 Name/Name2',
		responseStatus: /^HTTP\/1\.\d (\d{3}) (.*)$/gim,
		responseHeader: /^([^()<>@,;:\\"/[\]?={} \t]+)\s?:\s?(.*)/,
		responseBoundary: /boundary=(.*)/,
		responseDataServiceId: /\('([^']+)'\)/, // extracts ID from http://db01.lmdom.local/odata/Files('681') kinda string
		counter: /(\/\$count$)|(\/\$count\?)/
	},
	// key to build batch requests
	batch: {
		boundary: 'batch_',
		changeset: 'changeset_'
	},
	// keys defined in requests
	keys: {
		count: 'odata.count',
		error: 'odata.error',
		type: 'odata.type',
		editLink: 'odata.editLink',
		nextLink: 'odata.nextLink',
		mediaReadLink: 'odata.mediaReadLink',
		aggregate: 'workzone.odata.aggregate'
	}
};

export default Constants;
