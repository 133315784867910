import React, {
	useContext,
	createContext,
	useMemo,
	useCallback,
	useState
} from 'react';

import { joinPath, uuid, extend } from 'utils';

import { useDropzone } from 'react-dropzone';

import ChunkedUploady, {
	UploadyContext,
	UPLOADER_EVENTS
} from '@rpldy/chunked-uploady';

import OData, {
	useOdataMutation,
	useRequestOptions
} from 'components/WorkZone/Odata/Odata';

import { useTabSettings } from 'components/App/TabSettings';

const DropZoneContext = createContext({
	open: () => {
		/* empty */
	}
});

const ProgressContext = createContext<null | any>(null);

const CHUNK_SIZE = 5000000; // 5MB per chunk

export function useUploader() {
	return useContext(DropZoneContext);
}

export default function Uploader({
	children,
	style,
	flex,
	column,
	onUpload
}: React.PropsWithChildren<{
	style?: React.CSSProperties;
	flex?: boolean;
	column?: boolean;
	onUpload?: () => void;
}>) {
	const [currentlyUploading, setCurrentlyUploading] = useState<any>(null);

	const { server, fileId } = useTabSettings();

	const record = useOdataMutation('Records');

	const options = useRequestOptions();

	const listeners = useMemo(
		() => ({
			[UPLOADER_EVENTS.REQUEST_PRE_SEND]: async (request: any) => {
				const { items } = request;

				const fileName = items[0].file.name,
					slug = `${uuid()}_${fileName}`;

				const { headers } = await OData.setHeaders(
					extend(options, {
						headers: {
							slug,
							'content-disposition': `attachment;filename="${fileName}"`
						}
					})
				);

				return {
					options: {
						destination: {
							headers
						}
					}
				};
			},

			[UPLOADER_EVENTS.BATCH_START]: (batch: any) => {
				setCurrentlyUploading(batch);
			},

			[UPLOADER_EVENTS.BATCH_PROGRESS]: (batch: any) => {
				setCurrentlyUploading(batch);
			},

			[UPLOADER_EVENTS.BATCH_FINISH]: async (batch: any) => {
				await Promise.all(
					batch.items.map((item: any) => {
						const recordData = getRecordData(item, fileId);

						if (recordData) {
							return record.mutateAsync(recordData as any);
						}

						return true;
					})
				);

				setCurrentlyUploading(null);

				if (typeof onUpload === 'function') {
					onUpload();
				}
			}
		}),
		[fileId, onUpload, options, record]
	);

	const destination = useMemo(
		() => ({ url: joinPath(server, 'OData/Documents') }),
		[server]
	);

	return (
		<ChunkedUploady
			destination={destination}
			listeners={listeners}
			chunkSize={CHUNK_SIZE}
			sendWithFormData={false}
			withCredentials={true}
		>
			<ProgressContext.Provider value={currentlyUploading}>
				<DropZone style={style} flex={flex} column={column}>
					{children}
				</DropZone>
			</ProgressContext.Provider>
		</ChunkedUploady>
	);
}

function DropZone({
	children,
	style = {},
	flex,
	column
}: React.PropsWithChildren<{
	style?: React.CSSProperties;
	flex?: boolean;
	column?: boolean;
}>) {
	const rootProps = useMemo(() => {
		const props = { style };

		if (flex) {
			extend(props, {
				style: {
					display: 'flex'
				}
			});
		}

		if (column) {
			extend(props, {
				style: {
					flexDirection: 'column'
				}
			});
		}

		return props;
	}, [column, flex, style]);

	const uploadyContext = useContext(UploadyContext);

	const onDrop = useCallback(
		acceptedFiles => {
			if (uploadyContext) {
				uploadyContext.upload(acceptedFiles);
			}
		},
		[uploadyContext]
	);

	const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
		onDrop,
		noClick: true,
		noKeyboard: true
	});

	const contextValue = useMemo(
		() => ({
			open
		}),
		[open]
	);

	return (
		<DropZoneContext.Provider value={contextValue}>
			<div {...getRootProps(rootProps)}>
				<DropAccepter isAccepting={isDragAccept} />
				<UploadingProgress />
				<input {...getInputProps()} />
				{children}
			</div>
		</DropZoneContext.Provider>
	);
}

function DropAccepter({ isAccepting: accepting }: { isAccepting: boolean }) {
	const initial = useDropZoneLayerStyle();

	const style = useMemo(
		() => ({
			...initial,
			...(accepting
				? {
						visibility: 'visible'
				  }
				: {})
		}),
		[accepting, initial]
	) as React.CSSProperties;

	return (
		<div style={style}>
			Drop files here
			<br />
			to upload them to WorkZone
		</div>
	);
}

function UploadingProgress() {
	const uploading = useContext(ProgressContext);

	const initial = useDropZoneLayerStyle();

	const style = useMemo(
		() => ({
			...initial,
			...(uploading
				? {
						visibility: 'visible'
				  }
				: {})
		}),
		[uploading, initial]
	) as React.CSSProperties;

	return (
		<div style={style}>
			Uploading, {uploading?.completed.toFixed(0)}% completed
		</div>
	);
}

function useDropZoneLayerStyle() {
	return {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: 1,
		fontSize: '2rem',
		pointerEvents: 'none',
		opacity: 0.6,
		backgroundColor: '#FFF',
		visibility: 'hidden'
	};
}

function getRecordTitleFromFile(fileName = '') {
	// Remove "\" or "/" before the filename & Remove file extension
	return fileName.replace(/^.*[\\/]/, '').replace(/\.[^/.]+$/, '');
}

function getRecordData(item: any, fileId: string | number) {
	if (item.uploadStatus && OData.isFailedCode(item.uploadStatus)) {
		return null;
	}

	const response = item.uploadResponse.results[0].data;

	return {
		Title: getRecordTitleFromFile(item.file.name),
		RecordType_Value: 'DOK',
		State_Value: response.State,
		FileKey_Value: fileId,
		'Document@odata.bind': `Documents('${encodeURIComponent(
			response.RecordID
		)}')`,
		'odata.type': 'Som.Record'
	};
}
