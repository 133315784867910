import { useEffect, useState } from 'react';
import { BrowserAuthOptions } from '@azure/msal-browser';

export declare type WorkZoneAuthOptions = BrowserAuthOptions | null;

async function readAuthOptions(
	authorizationEndpoint: string,
	setOptions: (...args: any) => void
) {
	try {
		const response = await fetch(authorizationEndpoint, {
			method: 'HEAD',
			headers: {
				authorization: 'Bearer',
				prefer: 'auth-header'
			}
		});

		const authResponse = response.headers.get('www-authenticate');

		if (authResponse) {
			const azureAD = /authorization_uri="(.*?)".client_id="(.*?)"/g.exec(
				authResponse
			);

			if (azureAD) {
				const [, authority, clientId] = azureAD;

				if (authority && clientId) {
					setOptions({
						clientId,
						authority
					});
				} else {
					throw new Error(
						'www-authenticate header does not contain expected values'
					);
				}
			} else {
				throw new Error(
					'www-authenticate header does not contain expected values'
				);
			}
		} else {
			throw new Error('No www-authenticate header defined in response');
		}
	} catch {
		setOptions(null);
	}
}

/**
 * Workzone AzureAD configuration reading hook
 * @param authorizationEndpoint string
 * @returns WorkZoneConfiguration
 */
export function useWorkZoneAzureAuth(
	authorizationEndpoint: string
): WorkZoneAuthOptions {
	const [authOptions, setAuthOptions] = useState<WorkZoneAuthOptions>(null);

	useEffect(() => {
		// reset config on every endpoint change
		setAuthOptions(null);

		if (authorizationEndpoint) {
			readAuthOptions(authorizationEndpoint, setAuthOptions);
		}
	}, [authorizationEndpoint]);

	return authOptions;
}
