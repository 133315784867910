import Form from 'components/Form';
import { DropdownControlLayout, DropdownItem } from 'components/Form/Layout';
import Layout from 'components/Form/Layout/Layout';
import Notification from 'components/Notification';
import {
	useActionableContext,
	useEditDialogContext
} from 'components/Table/ActionableTable';
import {
	isRecordOnClosedCase,
	isRecordProtected,
	isRecordTrashed
} from 'components/Table/CollectionProperties/Record';
import { TableRowData } from 'types';
import {
	useOdata,
	useOdataMutationInQuery
} from 'components/WorkZone/Odata/Odata';
import { useWorkZone } from 'components/WorkZone/WorkZoneProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';

const DOCUMENT_STATES: { [state: string]: string[] } = {
	UP: ['UÅ'],
	UÅ: ['UP'],
	UL: ['UP', 'UÅ'],
	ARK: ['UP', 'UÅ', 'UL', 'O']
};

export function State({ row }: { row: TableRowData }) {
	const { canAuthorize, endpoint } = useWorkZone();

	const [states, setStates] = useState<DropdownItem[] | []>([]);

	const { setDialogOpen } = useEditDialogContext();
	const { source } = useActionableContext();

	const recordMutation = useOdataMutationInQuery({
		collection: 'Records',
		id: row.id,
		key: source?.key,
		select: source?.source.getProperties(),
		updateSupplementaryitems: true,
		childParentRefPropName: 'PrimaryRecordKey_Value',
		parentRefPropName: 'RecordKey'
	});

	const isRecordStateEditable = useMemo<boolean>(
		() =>
			!isRecordProtected(row) &&
			!isRecordOnClosedCase(row) &&
			!isRecordTrashed(row),
		[row]
	);

	const getItemsWithStateChangableFrom = useCallback(
		(downloadedStates: DropdownItem[]) =>
			downloadedStates.filter(
				state =>
					isRecordStateEditable &&
					DOCUMENT_STATES[state.id]?.includes(row.data.State_Value)
			),
		[isRecordStateEditable, row.data.State_Value]
	);

	const canSelect = canAuthorize && endpoint;

	const { data: availableStates, isLoading: availableStatesLoading } =
		useOdata({
			CustomDomains: {
				select: ['Value', 'Summary'],
				filter: `Type_Value eq 'AKTTILST' and Selection ne 'N'`,
				orderby: 'Rank,Summary'
			}
		});

	useEffect(() => {
		if (!availableStatesLoading && availableStates) {
			const mappedStates = availableStates.value.map((item: any) => ({
				header: item?.Summary as string,
				id: item?.Value as string
			}));
			const validStates = getItemsWithStateChangableFrom(mappedStates);
			setStates(validStates);
		}
	}, [
		availableStatesLoading,
		availableStates,
		getItemsWithStateChangableFrom
	]);

	const saveAction = useCallback(
		async (state: string) => {
			try {
				const result = await recordMutation.mutateAsync({
					ID: row.id,
					State_Value: state,
					'odata.type': 'Som.Record'
				} as any);

				if (typeof result['odata.error'] !== 'undefined') {
					Notification.error(result['odata.error'].message.value);

					return false;
				}

				return true;
			} catch (e: any) {
				Notification.error(e.message);
				return false;
			}
		},
		[recordMutation, row.id]
	);

	const onSubmit = useCallback(
		async e => {
			const state = e.getValues().State;

			if (
				!(state === row.data.State_Value || state === '') &&
				(await saveAction(state))
			) {
				setDialogOpen(false);
			}
		},
		[row.data.State_Value, saveAction, setDialogOpen]
	);

	const layout = useMemo(() => {
		const layoutTemplate = Layout.Records.State;
		const dropdownControlLayout = layoutTemplate.controls
			.State as unknown as DropdownControlLayout;
		dropdownControlLayout.items = states;
		dropdownControlLayout.disabled = !canSelect;
		return layoutTemplate;
	}, [canSelect, states]);

	return (
		<Form
			name="abstract"
			layout={layout}
			onSubmit={onSubmit}
			onCancel={() => setDialogOpen(false)}
		/>
	);
}
