import {
	initializeFileTypeIcons,
	getFileTypeIconAsHTMLString
} from '@fluentui/react-file-type-icons';

initializeFileTypeIcons();

const schemeMap = {
	Access: {
		scheme: 'ms-access',
		extensions: ['accdb', 'mdb']
	},
	Infopath: {
		scheme: 'ms-infopath',
		extensions: ['xsn', 'xsf']
	},
	Project: {
		scheme: 'ms-project',
		extensions: ['mpp', 'mpt']
	},
	Publisher: {
		scheme: 'ms-publisher',
		extensions: ['pub']
	},
	Visio: {
		scheme: 'ms-visio',
		extensions: [
			'vstx',
			'vstm',
			'vst',
			'vssx',
			'vssm',
			'vss',
			'vsl',
			'vsdx',
			'vsdm',
			'vsd',
			'vdw'
		]
	},
	Word: {
		scheme: 'ms-word',
		extensions: ['docx', 'doc', 'docm', 'dot', 'dotm', 'dotx', 'odt']
	},
	Powerpoint: {
		scheme: 'ms-powerpoint',
		extensions: [
			'pptx',
			'pptm',
			'ppt',
			'ppsx',
			'ppsm',
			'pps',
			'ppam',
			'ppa',
			'potx',
			'potm',
			'pot',
			'odp'
		]
	},
	Excel: {
		scheme: 'ms-excel',
		extensions: [
			'xltx',
			'xltm',
			'xlt',
			'xlsx',
			'xlsm',
			'xlsb',
			'xls',
			'xll',
			'xlam',
			'xla',
			'xlc',
			'xlm',
			'xlw',
			'ods',
			'csv',
			'prn'
		]
	}
};

export function getSchemeByExtension(extension: string) {
	extension = extension && extension.toLowerCase();

	const keys = Object.keys(schemeMap) as Array<keyof typeof schemeMap>;

	const appName = keys.find(name =>
		schemeMap[name].extensions.includes(extension)
	);

	if (appName) {
		return schemeMap[appName].scheme;
	}

	return null;
}

export function getIconByExtension(extension: string) {
	return getFileTypeIconAsHTMLString({ extension, size: 20 });
}
