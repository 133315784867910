import { ControlType } from 'components/Form/controls';

import { FormLayoutByName, FormButtonLayout } from './index';

const YesButton: FormButtonLayout = {
	role: 'submit',
	label: 'Yes'
};

const SaveButton: FormButtonLayout = {
	role: 'submit',
	label: 'Save'
};

const CancelButton: FormButtonLayout = {
	role: 'cancel',
	label: 'Cancel'
};

type FormCollection = 'Records';

const Layout: Record<FormCollection, FormLayoutByName> = {
	Records: {
		Title: {
			controls: {
				Title: {
					type: ControlType.TextBox,
					label: 'Title',
					order: '10',
					required: true,
					max: 256
				}
			},
			buttons: [SaveButton, CancelButton]
		},
		State: {
			controls: {
				State: {
					type: ControlType.Dropdown,
					label: 'State',
					order: '10',
					required: true,
					disabled: false
				}
			},
			buttons: [SaveButton, CancelButton]
		},
		Recycle: {
			controls: {
				Text: {
					type: ControlType.Text,
					content:
						'This document and its supplementary documents will be moved to the recycle bin. Do you want to continue?',
					label: 'Text',
					order: '10'
				}
			},
			buttons: [YesButton, CancelButton]
		},
		RecycleWithReason: {
			controls: {
				Text: {
					type: ControlType.Text,
					content:
						'This document and its supplementary documents will be moved to the recycle bin. Do you want to continue?',
					label: 'Text',
					order: '10'
				},
				Reason: {
					type: ControlType.Dropdown,
					label: 'Reason',
					order: '10',
					required: true,
					disabled: false,
					placeholder: 'Choose reason for recycling document'
				},
				Comment: {
					type: ControlType.TextBox,
					label: 'Recycle comment',
					order: '10',
					max: 256
				}
			},
			buttons: [SaveButton, CancelButton]
		}
	}
};

export default Layout;
