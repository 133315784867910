import { Box } from '@fluentui/react-northstar';

import { FilesTextColoredIcon } from '@fluentui/react-icons-northstar';

import { getIconByExtension } from 'components/Document/Scheme';

export function getIcon(extension: string) {
	const __html = getIconByExtension(extension);

	if (__html) {
		return (
			<Box
				style={{ width: '20px', height: '20px' }}
				dangerouslySetInnerHTML={{ __html }}
			></Box>
		);
	} else {
		return <FilesTextColoredIcon size="large" />;
	}
}
