import React from 'react';

import { FormSource } from 'components/Form';
import { FormLayout } from 'components/Form/Layout';

export enum ControlType {
	Text,
	TextBox,
	Dropdown
}

export const ControlByType: {
	[key: string]: React.LazyExoticComponent<React.ComponentType<any>>;
} = {
	[ControlType.Text]: React.lazy(
		() => import('components/Form/controls/Text')
	),
	[ControlType.TextBox]: React.lazy(
		() => import('components/Form/controls/TextBox')
	),
	[ControlType.Dropdown]: React.lazy(
		() => import('components/Form/controls/Dropdown')
	)
};

export function buildControlsFromLayout(
	layout: FormLayout,
	source: FormSource = {}
) {
	const { controls } = layout;

	if (controls) {
		const instances: JSX.Element[] = [];

		for (const [name, control] of Object.entries(controls)) {
			const Control = ControlByType[control.type];

			if (Control) {
				instances.push(
					<Control
						key={name}
						name={name}
						{...control}
						value={source[name]}
					/>
				);
			}
		}

		instances.sort((a, b) =>
			`${a.props.order}`.localeCompare(`${b.props.order}`)
		);

		return instances;
	}

	return null;
}
