import { Loader } from '@fluentui/react-northstar';
import Content from 'components/Content/Content';

function TableLoader() {
	return (
		<Content center style={{ height: '100vh' }}>
			<Loader />
		</Content>
	);
}

export default TableLoader;
