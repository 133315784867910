import { gridNestedBehavior } from '@fluentui/react-northstar';
import { ListLayout } from 'components/Table/utility/getDefaultLayout';
import { TableSourceOptions } from 'components/Table/TableSource';
import Constants from 'components/WorkZone/Odata/Constants';
import { useTable } from 'hook';
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import {
	AutoSizer,
	InfiniteLoader,
	List as ReactVirtualizedList,
	ListProps
} from 'react-virtualized';
import { ActionableTable, EditDialogContext } from './ActionableTable';
import Header from './Header';
import './Table.css';
import TableLoader from './TableLoader';

// Overrides ARIA attributes assigned by default, which break accessibility
const accessibilityListProperties: Partial<ListProps> = {
	'aria-label': '',
	'aria-readonly': undefined,
	containerRole: 'presentation',
	role: 'presentation'
};

type TableProps = {
	options: TableSourceOptions;
	flex?: boolean;
	force?: boolean;
	customLayout?: { [collection: string]: ListLayout };
	onSort?: (order: string) => void;
};

const VirtualizedTable: React.FC<TableProps> = ({
	options,
	flex = false,
	force = false,
	customLayout = undefined,
	onSort
}) => {
	const {
		rows,
		count,
		source,
		isLoading,
		isRowLoaded,
		loadMoreRows,
		rowGetter,
		rowRenderer,
		onScrollbarPresenceChange
	} = useTable(options, customLayout);
	const [trigger, setTrigger] = useState<boolean>(true);
	const { refetch } = source;
	const headerRef = useRef<HTMLDivElement>(null);
	const EditDialogContextObj = useContext(EditDialogContext);

	useEffect(() => {
		if (force) {
			refetch();
		}
	}, [force, refetch]);

	const onRerenderTable = useCallback(() => {
		// below code is re-mount component for getting proper width
		setTrigger(false);
		setTimeout(() => {
			setTrigger(true);
		}, 0);
	}, []);

	const autoSizerStyle = useMemo(() => {
		const style: React.CSSProperties = {
			width: 'auto',
			overflow: 'scroll'
		};

		if (flex) {
			style.flexGrow = 1;
		}

		return style;
	}, [flex]);

	if (!trigger) {
		return <TableLoader />;
	}

	return (
		<InfiniteLoader
			isRowLoaded={isRowLoaded}
			loadMoreRows={loadMoreRows}
			minimumBatchSize={Constants.serverPageSize}
			rowCount={count}
		>
			{({ onRowsRendered, registerChild }) => (
				<AutoSizer style={autoSizerStyle}>
					{({ height, width }) => {
						const getWidth = () => {
							return width >=
								(headerRef.current?.offsetWidth ?? 0)
								? width
								: headerRef.current?.offsetWidth ?? width;
						};

						return (
							<ActionableTable
								collection={options.collection}
								count={count}
								rows={rows}
								accessibility={gridNestedBehavior}
								style={{
									height: '100%',
									width: getWidth()
								}}
								source={source}
							>
								<Header
									source={source}
									width={
										width >
										(headerRef.current?.offsetWidth ?? 0)
											? width
											: 'fit-content'
									}
									onSort={onSort}
									ref={headerRef}
									onRerenderTable={onRerenderTable}
								/>

								{isLoading ||
								EditDialogContextObj?.isRefreshing ? (
									<TableLoader />
								) : (
									<ReactVirtualizedList
										ref={registerChild}
										rowCount={count}
										height={height}
										width={getWidth()}
										rowHeight={50}
										rowGetter={rowGetter}
										rowRenderer={rowRenderer}
										onRowsRendered={onRowsRendered}
										onScrollbarPresenceChange={
											onScrollbarPresenceChange
										}
										{...accessibilityListProperties}
									/>
								)}
							</ActionableTable>
						);
					}}
				</AutoSizer>
			)}
		</InfiniteLoader>
	);
};

export default VirtualizedTable;
