import { ColumnLayout } from './getDefaultLayout';

const sortByOrder = function (
	this: { [column: string]: ColumnLayout },
	a: string,
	b: string
) {
	if (this[a].order >= this[b].order) {
		return 1;
	}
	return -1;
};

export default sortByOrder;
