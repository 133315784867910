import { createContext, useContext, useMemo } from 'react';

import {
	PopupRequest,
	RedirectRequest,
	SsoSilentRequest
} from '@azure/msal-browser';

import { isValidEndpoint, joinPath } from 'utils';

import {
	useWorkZoneAzureAuth,
	WorkZoneAuthOptions
} from 'components/WorkZone/AzureConfig';

import { useWorkZone } from 'components/WorkZone/WorkZoneProvider';
import { ODATA_ENDPOINT } from 'components/App/Constants';

const WorkZoneAzureContext = createContext<WorkZoneAuthOptions>(null);

const baseRequest: PopupRequest | RedirectRequest | SsoSilentRequest = {
	scopes: ['openid', 'profile']
};

export type WorkZoneAzureAuthRequest = typeof baseRequest | null;

export function getWorkZoneAzureAuthRequestByEndpoint(endpoint: string) {
	return {
		scopes: [
			joinPath(endpoint, 'user_impersonation'),
			...(baseRequest.scopes || [])
		]
	};
}

export const useWorkZoneAzureAuthRequest = (): WorkZoneAzureAuthRequest => {
	const { endpoint } = useWorkZone();

	return useMemo(() => {
		if (endpoint) {
			return getWorkZoneAzureAuthRequestByEndpoint(endpoint);
		} else {
			return null;
		}
	}, [endpoint]);
};

export const useWorkZoneAzure = () => useContext(WorkZoneAzureContext);

export function WorkZoneAzureProvider({
	children
}: React.PropsWithChildren<unknown>) {
	const { endpoint } = useWorkZone();

	const options = useWorkZoneAzureAuth(
		isValidEndpoint(endpoint) ? joinPath(endpoint, ODATA_ENDPOINT) : ''
	);

	return (
		<WorkZoneAzureContext.Provider value={options}>
			{children}
		</WorkZoneAzureContext.Provider>
	);
}
