import GetDefaultLayout, {
	Customizable,
	ListLayout
} from 'components/Table/utility/getDefaultLayout';

export type TableSourceOptions = {
	collection: string;
	filter?: any;
	orderby?: string;
};

interface TableSourceInstance {
	getProperties(): string[];
	options: TableSourceOptions;
	collection: string;
}

const DEFAULT_PROPERTIES = ['ID', 'Summary'];

export default class TableSource implements TableSourceInstance {
	constructor(
		options: TableSourceOptions,
		customLayout?: { [collection: string]: ListLayout }
	) {
		this.options = options;
		this.customLayout = customLayout;
	}

	public options;
	public customLayout;

	get collection() {
		return this.options.collection;
	}

	update(options: TableSourceOptions) {
		this.options = options;
	}

	getProperties() {
		const layout = this.getLayout();

		if (layout) {
			return [
				...new Set([
					...DEFAULT_PROPERTIES,
					...Object.keys(layout.columns).filter(
						column => layout.columns[column].exists !== false
					),
					...(layout.data || [])
				])
			];
		}

		return DEFAULT_PROPERTIES;
	}

	getLayout() {
		const defaultLayoutForCollection =
			GetDefaultLayout()[this.options.collection] || null;
		if (this.customLayout && defaultLayoutForCollection) {
			const customLayoutForCollection =
				this.customLayout[this.options.collection];
			const constantColumns = Object.entries(
				defaultLayoutForCollection.columns
			).filter(entry => entry[1].customizable === Customizable.readonly);
			const customColumns = Object.entries(
				customLayoutForCollection.columns
			);
			customColumns.push(...constantColumns);

			const mergedLayout = defaultLayoutForCollection;
			mergedLayout.columns = Object.fromEntries(customColumns);

			return mergedLayout;
		}
		return defaultLayoutForCollection;
	}
}
