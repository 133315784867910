import { useMemo, useState, useCallback, useRef } from 'react';

import {
	Offset,
	Button,
	MenuButton,
	MoreIcon
} from '@fluentui/react-northstar';

import { useActionableContext, useEditDialogContext } from './ActionableTable';
import { ClickHandler, ColumnAction } from './Actions';

const buttonStyle: React.CSSProperties = {
	position: 'absolute',
	right: 0,
	top: '18%'
};

interface ActionableCellProps {
	cell: any;
	actions: ColumnAction[];
	trigger: any;
	rowindex: number;
	isActionsColumn: boolean;
	isMoreOptionsButtonVisible?: boolean;
}

export function ActionableCell({
	cell,
	actions,
	trigger,
	rowindex,
	isActionsColumn,
	isMoreOptionsButtonVisible
}: ActionableCellProps) {
	const { setDialogName, setDialogOpen, setDialogContent } =
		useEditDialogContext();

	const [shouldOpen, setMenuOpen] = useState(false);

	const [offset, setOffset] = useState<Offset>([0, 0]);

	const target = useRef();

	const onContextMenu = useCallback((e: any) => {
		target.current = e.currentTarget;

		setOffset([0, 0]);
	}, []);

	const iconStyle = {
		visibility: 'hidden'
	} as React.CSSProperties;

	if (isMoreOptionsButtonVisible) {
		iconStyle.visibility = 'visible';
	}

	const { rows } = useActionableContext();
	const row = rows[rowindex];

	const menu = useMemo(() => {
		const reducedItems = actions.reduce(
			(acc: any[], action: ColumnAction, i: number) => {
				const isActionVisible =
					typeof action.isVisible === 'function'
						? action.isVisible(row)
						: true;
				if (isActionVisible) {
					if (action.isDialogAction) {
						acc.push({
							key: [i, action.label].join('-'),
							content: action.label,
							onClick: () => {
								setDialogOpen(true);

								setDialogName(
									typeof action.description === 'function'
										? action.description(row)
										: ''
								);

								setDialogContent(action.handler(row));
							}
						});
					} else if (!action.isInlineAction) {
						const clickHandler = new ClickHandler();
						acc.push({
							key: [i, action.label].join('-'),
							content: action.handler(row, clickHandler),
							onClick: () => {
								clickHandler.handleClick();
							}
						});
					}
				}

				return acc;
			},
			[]
		);

		return { items: reducedItems };
	}, [actions, row, setDialogOpen, setDialogName, setDialogContent]);

	const onThreeDotsClick = useCallback((e: any) => {
		target.current = e.currentTarget;

		setOffset([-10, 0]);

		setMenuOpen(true);
	}, []);

	let cellContent = cell.content;

	const inlineAction = actions.find(action => action.isInlineAction);

	if (inlineAction && typeof inlineAction.cellRenderer === 'function') {
		cellContent = inlineAction.cellRenderer(row, cell.content);
	}

	const actionableContent = (
		<>
			{cellContent}
			<Button
				data-role="more-options"
				tabIndex={-1}
				style={buttonStyle}
				icon={<MoreIcon style={iconStyle} />}
				text
				iconOnly
				title="More options"
				onClick={onThreeDotsClick}
			/>
		</>
	);

	const actionsColumnContent = (
		<Button
			tabIndex={-1}
			style={buttonStyle}
			icon={<MoreIcon />}
			text
			iconOnly
			title="More options"
			onClick={onThreeDotsClick}
		/>
	);

	const renderedTrigger = trigger({
		...cell,
		onContextMenu,
		content: isActionsColumn ? actionsColumnContent : actionableContent
	});

	return (
		<MenuButton
			position="after"
			align="top"
			offset={offset}
			trigger={renderedTrigger}
			target={target.current}
			open={shouldOpen}
			onOpenChange={(e, data) => setMenuOpen(data?.open || false)}
			menu={menu}
			contextMenu
		/>
	);
}
