import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
	Flex,
	Grid,
	Input,
	ListItem,
	MenuIcon
} from '@fluentui/react-northstar';
import { useCallback, useState } from 'react';
import { ColumnItem } from './ColumnSettingsDialog';

export function SortableItem({
	item,
	index
}: {
	item: ColumnItem;
	index: number;
}) {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: item.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition: transition ?? ''
	};

	const [itemWidth, setWidth] = useState(
		parseInt(item?.value?.width?.toString() ?? '0')
	);

	const onChange = useCallback(
		(control, value) => {
			let newWidth = parseInt(value?.value ?? '0');
			if (newWidth < 0) {
				newWidth = 0;
			}

			item.value.width = newWidth;

			setWidth(newWidth);
		},
		[item.value]
	);

	return (
		<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
			<Grid columns="75% 25%">
				<Flex fill vAlign="center" style={{ marginLeft: '10px' }}>
					<MenuIcon size="smallest" />
					<ListItem
						style={{ paddingLeft: '0' }}
						index={index}
						header={item.header}
						content={item.content}
						media={item.media}
					/>
				</Flex>
				<Input
					fluid
					placeholder="Width"
					type="number"
					min="0"
					value={itemWidth}
					onChange={onChange}
				/>
			</Grid>
		</div>
	);
}
