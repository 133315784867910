import { ThemeInput, gridRowBehavior, Table } from '@fluentui/react-northstar';
import { useCallback, useState } from 'react';
import { ColumnAction, getActions } from './Actions';
import BodyCell from './BodyCell';
import { ColumnLayout } from './utility/getDefaultLayout';
import type { InfiniteSource } from 'hook';
import { TableRowData } from 'types';

const sortByOrder = function (
	this: { [column: string]: ColumnLayout },
	a: string,
	b: string
) {
	if (this[a].order >= this[b].order) {
		return 1;
	}
	return -1;
};

type TableBodyCell = {
	name: string;
	layout: ColumnLayout;
};

function Row({
	source,
	row,
	style,
	index
}: {
	source: InfiniteSource;
	row?: TableRowData;
	style: React.CSSProperties;
	index: number;
}) {
	const { source: tableSource } = source;
	const [isMoreOptionsButtonVisible, setIsMoreOptionsButtonVisible] =
		useState(false);
	const layout = tableSource.getLayout();

	const onMouseOver = useCallback(() => {
		setIsMoreOptionsButtonVisible(true);
	}, []);

	const onMouseOut = useCallback(() => {
		setIsMoreOptionsButtonVisible(false);
	}, []);

	const getRowActions = useCallback(
		(columns: TableBodyCell[]) => {
			let actionsSum: ColumnAction[] = [];

			columns.forEach(column => {
				const actions = getActions(tableSource.collection, column.name);

				if (Array.isArray(actions)) {
					actionsSum = [...actionsSum, ...actions];
				}
			});

			return actionsSum;
		},
		[tableSource.collection]
	);

	if (layout) {
		const columns: TableBodyCell[] = Object.keys(layout.columns)
			.sort(sortByOrder.bind(layout.columns))
			.map(name => {
				return {
					name,
					layout: layout.columns[name]
				};
			});

		const getRowStyles = ({ theme }: { theme: ThemeInput }) => {
			return {
				'[tabindex="0"]': {
					backgroundColor:
						theme.siteVariables?.colorScheme.brand.borderActive
				}
			};
		};

		const getActionsTitleColumn = (
			actions: ColumnAction[] | null,
			column: TableBodyCell
		) => {
			if (!Array.isArray(actions)) {
				return null;
			}

			return column.layout.isDefault ? getRowActions(columns) : null;
		};

		return (
			<Table.Row
				style={style}
				styles={getRowStyles}
				accessibility={gridRowBehavior}
				aria-rowindex={index + 1}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
			>
				{columns.map(column => {
					const actions = getActions(
						tableSource.collection,
						column.name
					);

					return (
						<BodyCell
							key={column.layout.order}
							column={column}
							cell={row?.cells[column.name]}
							rowindex={index}
							actions={getActionsTitleColumn(actions, column)}
							dataColumIndex={column.layout.order}
							columnClassName={`column-index-${column.layout.order}`}
							isMoreOptionsButtonVisible={
								isMoreOptionsButtonVisible
							}
						/>
					);
				})}
			</Table.Row>
		);
	} else {
		return null;
	}
}

export default Row;
