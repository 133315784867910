import { gridRowBehavior, Table, Divider } from '@fluentui/react-northstar';
import { forwardRef, useCallback, useState } from 'react';

import type { InfiniteSource } from 'hook';
import HeaderCell from './HeaderCell';
import { ColumnSort } from './utility/getDefaultLayout';
import sortByOrder from './utility/sortByOrder';

const Header = forwardRef(
	(
		{
			source,
			width,
			onSort,
			onRerenderTable,
			style
		}: {
			source: InfiniteSource;
			width: number | string;
			onSort?: (order: string) => void;
			onRerenderTable: () => void;
			style?: React.CSSProperties;
		},
		ref: React.ForwardedRef<HTMLDivElement>
	) => {
		const { source: tableSource } = source;

		const layout = tableSource.getLayout();
		const [isResizing, setIsResizing] = useState(false);

		const [currentSortColumn, setSortColumn] = useState('ID');
		const [currentSortDirection, setSortDirection] =
			useState<keyof typeof ColumnSort>('asc');

		const onHeaderSort = useCallback(
			(column: string, order: keyof typeof ColumnSort) => {
				order = currentSortColumn !== column ? ColumnSort.asc : order;
				setSortColumn(column);
				setSortDirection(order);
				if (onSort) {
					onSort(`${column} ${order}`);
				}
			},
			[currentSortColumn, onSort]
		);

		if (!layout) {
			return null;
		}

		const columns = Object.keys(layout.columns)
			.sort(sortByOrder.bind(layout.columns))
			.map(name => {
				return {
					name,
					column: layout.columns[name]
				};
			});

		return (
			<Table.Row
				header
				accessibility={gridRowBehavior}
				style={{ width, ...style }}
				ref={ref}
			>
				<Divider
					fitted
					style={{
						position: 'absolute',
						left: 0,
						top: '100%',
						height: '0.0625rem',
						width: '100vw'
					}}
				/>
				{columns.map(({ name, column }) => (
					<HeaderCell
						key={column.order}
						dataColumIndex={column.order}
						name={name}
						column={column}
						source={source}
						onSort={onHeaderSort}
						onRerenderTable={onRerenderTable}
						sortDirection={
							name === currentSortColumn
								? currentSortDirection
								: undefined
						}
						isResizing={isResizing}
						setIsResizing={setIsResizing}
					/>
				))}
			</Table.Row>
		);
	}
);

export default Header;
