import Form from 'components/Form';
import {
	DropdownControlLayout,
	DropdownItem,
	TextBoxControlLayout
} from 'components/Form/Layout';
import Layout from 'components/Form/Layout/Layout';
import Notification from 'components/Notification';
import {
	useActionableContext,
	useEditDialogContext
} from 'components/Table/ActionableTable';
import { handleRecordEditErrors } from 'components/Table/CollectionProperties/Record';
import { TableRowData } from 'types';
import {
	useOdata,
	useOdataRemoveMutationInQuery,
	useRequestOptions
} from 'components/WorkZone/Odata/Odata';
import { useWorkZone } from 'components/WorkZone/WorkZoneProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';

type RecycleReason = {
	header: string;
	id: string;
	key: string;
};

export function RecycleWithReason({ row }: { row: TableRowData }) {
	const { source } = useActionableContext();
	const [hasAccessCode, setHasAccessCode] = useState(false);

	const [reasons, setReasons] = useState<DropdownItem[] | []>([]);

	const [isCommentRequired, setIsCommentRequired] = useState(false);
	const { canAuthorize, endpoint } = useWorkZone();

	const requestOptions = useRequestOptions();
	const { setDialogOpen } = useEditDialogContext();

	useMemo(() => {
		setIsCommentRequired(
			row.data.RetentionPolicy?.RetentionPolicy?.CommentRequired
		);
	}, [row]);

	const canSelect = canAuthorize && endpoint;

	const { data: validAccessCodes, isLoading: validAccessCodesLoading } =
		useOdata({
			AccessCodes: {
				select: 'ID',
				filter: `UserName eq '@Me' and Value eq 'SOFTDELETE'`
			}
		});

	const { data: availableReasons, isLoading: availableReasonsLoading } =
		useOdata({
			CustomDomains: {
				select: ['Value', 'Summary'],
				filter: `Type_Value eq 'SDR'`
			}
		});

	useEffect(() => {
		if (!availableReasonsLoading && availableReasons) {
			const mappedReasons = availableReasons.value.map((item: any) => ({
				header: item?.Summary as string,
				id: item?.Value as string
			}));
			setReasons(mappedReasons);
		}
	}, [availableReasonsLoading, availableReasons]);

	useEffect(() => {
		if (!validAccessCodesLoading && validAccessCodes) {
			setHasAccessCode(!!validAccessCodes.value.length);
		}
	}, [validAccessCodesLoading, validAccessCodes]);

	const recordMutation = useOdataRemoveMutationInQuery({
		collection: 'Records',
		id: row.id,
		key: source?.key,
		select: source?.source.getProperties(),
		updateSupplementaryitems: true,
		childParentRefPropName: 'PrimaryRecordKey_Value',
		parentRefPropName: 'RecordKey'
	});

	const saveAction = useCallback(
		async (recycleReason: RecycleReason, recycleComment: string) => {
			try {
				const result = await recordMutation.mutateAsync({
					ID: row.id,
					ThrashedBy_Value: '@Me',
					DeleteReason_Value: recycleReason,
					DeleteComment: recycleComment,
					'odata.type': 'Som.Record'
				} as any);

				if (typeof result['odata.error'] !== 'undefined') {
					await handleRecordEditErrors(result, row, requestOptions);
					return false;
				}

				return true;
			} catch (e: any) {
				Notification.error(e.message);
				return false;
			}
		},
		[recordMutation, row, requestOptions]
	);

	const onSubmit = useCallback(
		async e => {
			const reason = e.getValues().Reason;
			const comment = e.getValues().Comment;

			if (
				!(reason === row.data.Reason_Value || reason === '') &&
				(await saveAction(reason, comment))
			) {
				setDialogOpen(false);
			}
		},
		[row.data.Reason_Value, saveAction, setDialogOpen]
	);

	const layout = useMemo(() => {
		const layoutTemplate = Layout.Records.RecycleWithReason;
		const dropdownControlLayout = layoutTemplate.controls
			.Reason as unknown as DropdownControlLayout;
		dropdownControlLayout.items = reasons;
		dropdownControlLayout.disabled = !canSelect;
		const commentLayout = layoutTemplate.controls
			.Comment as unknown as TextBoxControlLayout;
		commentLayout.required = isCommentRequired;
		return layoutTemplate;
	}, [canSelect, isCommentRequired, reasons]);

	return (
		<Form
			name="abstract"
			layout={layout}
			submitAllowed={hasAccessCode}
			onSubmit={onSubmit}
			onCancel={() => setDialogOpen(false)}
		/>
	);
}
