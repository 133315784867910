import { State } from './CollectionActions/Record/State';
import { Title } from './CollectionActions/Record/Title';

import { TableRowData } from 'types';
import { OpenInWorkZone } from './CollectionActions/Record/OpenInWorkZone';
import { OpenByUri } from './CollectionActions/Record/OpenByUri';
import {
	canBeRecycled,
	hasScheme,
	isJournalized
} from './CollectionProperties/Record';
import { Recycle } from './CollectionActions/Record/Recycle';
import { RecycleWithReason } from './CollectionActions/Record/RecycleWithReason';
import { OpenInWorkZoneInline } from './CollectionActions/Record/OpenInWorkZoneInline';

export function collectionHasActions(collection: string) {
	return !!Actions[collection];
}

export function getActions(collection: string, columnName: string) {
	const actionsForCollection = Actions[collection];
	if (actionsForCollection) {
		return actionsForCollection.columns[columnName];
	}
	return null;
}

export type ColumnAction = {
	isVisible?: (row: TableRowData) => boolean;
	isDialogAction?: boolean;
	isInlineAction?: boolean;
	label?: string;
	description?: (row: TableRowData) => string;
	handler: (
		row: TableRowData,
		clickHandler?: ClickHandler
	) => React.ReactNode;
	cellRenderer?: (row: TableRowData, text?: string) => React.ReactNode;
};

export class ClickHandler {
	public clickAction: () => void = () => null;

	handleClick() {
		if (this.clickAction) {
			this.clickAction();
		}
	}
}

type TableColunmActionsLayout = {
	[collection: string]: {
		columns: {
			[column: string]: ColumnAction[];
		};
	};
};

const Actions: TableColunmActionsLayout = {
	Records: {
		columns: {
			State_Summary: [
				{
					isDialogAction: true,
					label: 'Edit State',
					description: row =>
						`Edit state for document: ${row.data.Title}`,
					handler: row => <State row={row} />
				}
			],
			Title: [
				{
					isDialogAction: true,
					label: 'Edit Title',
					description: row =>
						`Edit title for document: ${row.data.Title}`,
					handler: row => <Title row={row} />
				},
				{
					isInlineAction: true,
					handler: () => null,
					cellRenderer: (row, text) => (
						<OpenInWorkZoneInline
							hasTooltip
							row={row}
							label={text}
						/>
					)
				}
			],
			action: [
				{
					label: 'Open in WorkZone',
					handler: (row, clickHandler) => (
						<OpenInWorkZone
							row={row}
							label={'Open in WorkZone'}
							clickHandler={clickHandler}
						/>
					)
				},
				{
					isVisible: row => hasScheme(row),
					label: 'Open',
					handler: (row, clickHandler) => (
						<OpenByUri
							row={row}
							label={'Open'}
							clickHandler={clickHandler}
						/>
					)
				},
				{
					isVisible: row => !isJournalized(row) && canBeRecycled(row),
					isDialogAction: true,
					label: 'Recycle',
					description: row => `Recycle: ${row.data.Title}`,
					handler: row => <Recycle row={row} />
				},
				{
					isVisible: row => isJournalized(row) && canBeRecycled(row),
					isDialogAction: true,
					label: 'Recycle',
					description: row => `Recycle: ${row.data.Title}`,
					handler: row => <RecycleWithReason row={row} />
				}
			]
		}
	}
};
