import mergeWith from 'lodash.mergewith';

type AnyFunction = (...args: any[]) => any;

/**
 * Custom extend with arrays merging, not overwriting them
 * @param {any} args
 */
export default function extend<T extends any[]>(...args: T) {
	const toMerge = [
		...args,
		(objValue: any[] | any, srcValue: any[] | any) => {
			if (Array.isArray(objValue)) {
				return objValue.concat(srcValue);
			}
		}
	];

	return (mergeWith as AnyFunction)(...toMerge); // eslint-disable-line no-extra-parens
}
