import { useCallback, useMemo } from 'react';

import { useMsal } from '@azure/msal-react';
import {
	InteractionRequiredAuthError,
	IPublicClientApplication,
	PopupRequest,
	SilentRequest
} from '@azure/msal-browser';

import { xml2json } from 'xml-js';

import {
	useInfiniteQuery,
	useMutation,
	UseMutationOptions,
	useQuery,
	useQueryClient
} from 'react-query';
import { joinPath, extend } from 'utils';

import { ODATA_ENDPOINT } from 'components/App/Constants';
import Constants from 'components/WorkZone/Odata/Constants';
import UrlBuilder, { ODataQuery } from 'components/WorkZone/Odata/UrlBuilder';

import { useWorkZone } from 'components/WorkZone/WorkZoneProvider';
import { getWorkZoneAzureAuthRequestByEndpoint } from 'components/WorkZone/WorkZoneAzureProvider';

const CONTENT_TYPE_JSON = 'application/json';
const CONTENT_TYPE_TEXT = 'text/plain';
const CONTENT_TYPE_XML = 'application/xml';
const CHARSET = 'charset=utf-8';

export enum ODataAccept {
	Text = 'text',
	JSON = 'json',
	XML = 'xml',
	Binary = 'binary'
}

export enum ODataTake {
	Raw = 'raw',
	First = 'first',
	Last = 'last'
}

type ODataOptions =
	| undefined
	| (RequestInit & {
			accept?: ODataAccept;
			take?: ODataTake;
			meta?: 'full' | 'minimal';
			enabled?: boolean;
			pca?: IPublicClientApplication;
	  });

type AuthorizationHeaderOptions = {
	pca: IPublicClientApplication;
};

type ODataData =
	| string
	| {
			ID?: string | number;
	  };

type ODataResponse =
	| string
	| {
			value: any[];
	  };

export default class OData {
	public static endpoint = '/';

	public static path: string = ODATA_ENDPOINT;

	static usePath(path: string) {
		OData.path = path;
	}

	static useEndpoint(endpoint: string) {
		OData.endpoint = endpoint;
	}

	static async post(
		query: ODataQuery,
		data: ODataData,
		options: ODataOptions
	) {
		// try to guess method from data
		const method =
			typeof data !== 'string' &&
			typeof data.ID !== 'undefined' &&
			data.ID
				? 'PATCH'
				: 'POST';

		return OData.request(
			query,
			extend(
				{},
				{
					method,
					headers: {
						'content-type': [CONTENT_TYPE_JSON, CHARSET].join(';')
					},
					body: typeof data === 'string' ? data : JSON.stringify(data)
				},
				options
			)
		);
	}

	static async request(query: ODataQuery, options: ODataOptions) {
		const requestOptions = await OData.setOptions({ ...options });

		const response = await fetch(OData.buildUrl(query), requestOptions);

		return OData.processResponse(response, requestOptions);
	}

	static async processResponse(response: Response, options: ODataOptions) {
		const data = await response.text();

		if (options?.accept === ODataAccept.Text) {
			return data;
		}

		if (options?.accept === ODataAccept.XML) {
			const parsed = xml2json(data, { compact: true });
			return OData.processJSONResponse(parsed, options);
		}

		return OData.processJSONResponse(data, options);
	}

	static processJSONResponse(data: string, options: ODataOptions) {
		if (data) {
			const result = JSON.parse(data);

			if (options?.take) {
				return OData.takeFromResponse(result, options.take);
			}
		}

		return data;
	}

	static takeFromResponse(response: ODataResponse, take: string) {
		if (take === ODataTake.Raw) {
			return response;
		}

		if (typeof response !== 'string' && Array.isArray(response.value)) {
			if (take === ODataTake.First) {
				return response.value[0];
			}

			if (take === ODataTake.Last) {
				return response.value[response.value.length - 1];
			}
		}

		return response;
	}

	static setOptions(options: ODataOptions = {}) {
		if (typeof options?.take === 'undefined') {
			options.take = ODataTake.Raw; //return entire response back by default
		}

		return OData.setHeaders(options);
	}

	static async setHeaders(options: ODataOptions) {
		const authorization = await OData.getAuthorizationHeader(
			options as AuthorizationHeaderOptions
		);

		const accept = OData.acceptByOption(options);

		return extend({}, options, {
			headers: {
				...authorization,
				Accept: accept.join(';'),
				FixedPageSize: 'true', // fixed size on nextLink navigation
				'Accept-Language': '', // dropping this header makes OData always use User/CultureName
				'UseLog-Application': 'teams' // statistics
			}
		});
	}

	static async getAuthorizationHeader({ pca }: AuthorizationHeaderOptions) {
		const request = getWorkZoneAzureAuthRequestByEndpoint(OData.endpoint);

		const accounts = pca.getAllAccounts();

		const silentRequest = {
			...request,
			account: accounts[0] || {}
		};

		const response = await pca
			.acquireTokenSilent(silentRequest as SilentRequest)
			.catch(async error => {
				if (error instanceof InteractionRequiredAuthError) {
					// fallback to interaction when silent call fails
					return pca.acquireTokenPopup(request as PopupRequest);
				}
			});

		return response
			? { authorization: `Bearer ${response.accessToken}` }
			: {};
	}

	static acceptByOption(options: ODataOptions) {
		if (options?.accept === ODataAccept.Text) {
			return [CONTENT_TYPE_TEXT];
		}
		if (options?.accept === ODataAccept.XML) {
			return [CONTENT_TYPE_XML]; //TODO make minimal with xml work
		}

		return [
			CONTENT_TYPE_JSON,
			CHARSET,
			`odata=${options?.meta || 'minimal'}metadata`
		];
	}

	static hash(value: any) {
		return JSON.stringify(value);
	}

	static buildUrl(query: ODataQuery) {
		const url = UrlBuilder.queryToUrl(query);

		return joinPath(OData.endpoint, OData.path, url);
	}

	static isFailedCode(code: number) {
		return ~~(+code / 100) !== 2; // fail if code is not 2**
	}
}

export function useOdata(query: ODataQuery, options: ODataOptions = {}) {
	const requestOptions = useRequestOptions(options);

	const queryCall = useCallback(
		() => OData.request(query, requestOptions),
		[query, requestOptions]
	);

	const key = OData.hash(query);

	return {
		key,
		...useQuery(key, queryCall, {
			enabled:
				typeof options?.enabled !== 'undefined'
					? options?.enabled
					: true,
			refetchOnWindowFocus: false
		})
	};
}

export function useInifiniteOdata(
	query: ODataQuery,
	options: ODataOptions = {}
) {
	const requestOptions = useRequestOptions(options);

	const queryCall = useCallback(
		({ pageParam = null }) =>
			OData.request(pageParam || query, requestOptions),
		[query, requestOptions]
	);

	const key = OData.hash(query);

	return {
		key,
		...useInfiniteQuery(key, queryCall, {
			enabled:
				typeof options.enabled !== 'undefined' ? options.enabled : true,
			getNextPageParam: (lastPage /* , pages */) =>
				lastPage[Constants.keys.nextLink],
			refetchOnWindowFocus: false
		})
	};
}

export function useOdataMutation(
	collection: ODataQuery,
	options: ODataOptions = {},
	mutationOptions: UseMutationOptions<any, unknown, void, unknown> = {}
) {
	const requestOptions = useRequestOptions(options);

	const queryCall = useCallback(
		data => OData.post(collection, data, requestOptions),
		[collection, requestOptions]
	);

	return useMutation((data: any) => queryCall(data), mutationOptions);
}

type MutationInQueryOptions = {
	collection: string;
	id: string | number;
	key: any;
	select: string | string[] | undefined;
	updateSupplementaryitems?: boolean;
	childParentRefPropName?: string;
	parentRefPropName?: string;
};

export function useOdataRemoveMutationInQuery(
	{
		collection,
		id = 0,
		key = '',
		updateSupplementaryitems = false,
		childParentRefPropName = '',
		parentRefPropName = ''
	}: MutationInQueryOptions,
	options: ODataOptions = {}
) {
	const query = `${collection}('${id}')`;

	const queryClient = useQueryClient();

	const onSuccess = useCallback(
		async (data, variables, context) => {
			if (typeof data['odata.error'] !== 'undefined') {
				return;
			}

			queryClient.setQueryData(key, (queryData: any) => {
				let parentRefValue: any;

				for (const page of queryData.pages) {
					const rowIndex = page.value.findIndex(
						(entry: any) => entry.ID === id
					);

					if (~rowIndex) {
						parentRefValue =
							page.value[rowIndex][parentRefPropName];

						page.value.splice(rowIndex, 1);
						page.at = new Date(); // force table render using new data
						break;
					}
				}

				if (
					updateSupplementaryitems &&
					childParentRefPropName &&
					parentRefPropName &&
					parentRefValue
				) {
					for (const page of queryData.pages) {
						const filtered = page.value.filter(
							(entry: any) =>
								entry[childParentRefPropName] !== parentRefValue
						);

						if (filtered.length !== page.value.length) {
							page.value = filtered;
							page.at = new Date();
						}
					}
				}

				return JSON.parse(JSON.stringify(queryData));
			});
		},
		[
			childParentRefPropName,
			id,
			key,
			parentRefPropName,
			queryClient,
			updateSupplementaryitems
		]
	);

	return useOdataMutation(query, options, { onSuccess });
}

export function useOdataMutationInQuery(
	{
		collection,
		id = 0,
		key = '',
		select = '',
		updateSupplementaryitems = false,
		childParentRefPropName = '',
		parentRefPropName = ''
	}: MutationInQueryOptions,
	options: ODataOptions = {}
) {
	const query = `${collection}('${id}')`;
	const childrenQuery = `${collection}`;

	const requestOptions = useRequestOptions(options);

	const queryClient = useQueryClient();

	const onSuccess = useCallback(async () => {
		const updated = await OData.request(
			{
				[query]: {
					select
				}
			},
			requestOptions
		);

		let updatedChildren: any;
		if (
			updateSupplementaryitems &&
			childParentRefPropName &&
			parentRefPropName
		) {
			const childrenFilter = `${childParentRefPropName} eq '${updated[parentRefPropName]}' and ${parentRefPropName} ne '${updated[parentRefPropName]}'`;
			updatedChildren = await OData.request(
				{
					[childrenQuery]: {
						filter: childrenFilter,
						select
					}
				},
				requestOptions
			);
		}

		queryClient.setQueryData(key, (data: any) => {
			for (const page of data.pages) {
				const rowIndex = page.value.findIndex(
					(entry: any) => entry.ID === id
				);

				if (~rowIndex) {
					page.value[rowIndex] = updated;
					page.at = new Date(); // force table render using new data
					if (!updatedChildren) {
						break;
					}
				}

				if (updatedChildren) {
					for (const child of page.value) {
						if (
							child[childParentRefPropName] ===
								updated[parentRefPropName] &&
							child[parentRefPropName] !==
								child[childParentRefPropName]
						) {
							const updatedChild = updatedChildren.value.find(
								(item: any) => item.ID === child.ID
							);

							const childRowIndex = page.value.findIndex(
								(entry: any) => entry.ID === child.ID
							);
							page.value[childRowIndex] = updatedChild;
							page.at = new Date();
						}
					}
				}
			}

			return JSON.parse(JSON.stringify(data));
		});
	}, [
		childParentRefPropName,
		childrenQuery,
		id,
		key,
		parentRefPropName,
		query,
		queryClient,
		requestOptions,
		select,
		updateSupplementaryitems
	]);

	return useOdataMutation(query, options, { onSuccess });
}

export function useRequestOptions(options: ODataOptions = {}) {
	const { endpoint } = useWorkZone();

	const { instance } = useMsal();

	OData.useEndpoint(endpoint);

	return useMemo(
		() =>
			extend(options, {
				// eslint-disable-line sonarjs/no-identical-functions
				pca: instance
			}),
		[instance, options]
	);
}
