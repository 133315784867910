import { Box } from '@fluentui/react-northstar';
import { useTabSettings } from 'components/App/TabSettings';
import { ClickHandler } from 'components/Table/Actions';
import { TableRowData } from 'types';
import { getWorkZoneLink } from 'utils';

export function OpenInWorkZone({
	row,
	label,
	clickHandler
}: {
	row: TableRowData;
	label?: string;
	clickHandler?: ClickHandler;
}) {
	const { server } = useTabSettings();

	if (clickHandler) {
		clickHandler.clickAction = () =>
			window.open(getWorkZoneLink(server, row), '_blank', 'noreferrer');
	}

	if (!label) {
		return null;
	}

	return <Box>{label}</Box>;
}
