import * as Teams from '@microsoft/teams-js';

import { joinPath, isValidEndpoint } from 'utils';

import { AUTH_ENDPOINT, ODATA_ENDPOINT } from 'components/App/Constants';

import { useEffect } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';

import { useWorkZoneAzureAuth } from 'components/WorkZone/AzureConfig';

import { MsalProviderInstance } from 'components/WorkZone/WorkZoneProvider';
import { getWorkZoneAzureAuthRequestByEndpoint } from 'components/WorkZone/WorkZoneAzureProvider';

import ErrorComponent from 'components/WorkZone/Error';
import LoadingComponent from 'components/WorkZone/Loading';

function getEndpoint() {
	return window.localStorage.getItem(AUTH_ENDPOINT) || '';
}

export default function Authenticate() {
	return (
		<MsalProviderFactory>
			<Authenticator />
		</MsalProviderFactory>
	);
}

function MsalProviderFactory({ children }: React.PropsWithChildren<unknown>) {
	const endpoint = getEndpoint();

	const config = useWorkZoneAzureAuth(
		isValidEndpoint(endpoint) ? joinPath(endpoint, ODATA_ENDPOINT) : ''
	);

	if (config) {
		const authenticationRequest =
			getWorkZoneAzureAuthRequestByEndpoint(endpoint);

		return (
			<MsalProviderInstance auth={config} request={authenticationRequest}>
				{children}
			</MsalProviderInstance>
		);
	} else {
		return <>{children}</>;
	}
}

function Authenticator() {
	const endpoint = getEndpoint();

	const authenticationRequest =
		getWorkZoneAzureAuthRequestByEndpoint(endpoint);

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authenticationRequest}
			errorComponent={ErrorComponent}
			loadingComponent={LoadingComponent}
		>
			{({ result }: { result: any }) => <Authenticated result={result} />}
		</MsalAuthenticationTemplate>
	);
}

function Authenticated({ result }: { result: any }) {
	useEffect(() => {
		Teams.initialize(() => {
			Teams.authentication.initialize();

			Teams.authentication.notifySuccess(result);
		});
	}, [result]);

	return <LoadingComponent />;
}
