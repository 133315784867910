import {
	createContext,
	useContext,
	useEffect,
	useState,
	useMemo,
	useCallback
} from 'react';

import * as Teams from '@microsoft/teams-js';

import {
	PublicClientApplication,
	InteractionType,
	BrowserAuthOptions,
	InteractionStatus
} from '@azure/msal-browser';

import { BrowserConstants } from '@azure/msal-browser/dist/utils/BrowserConstants';

import {
	MsalProvider,
	MsalAuthenticationTemplate,
	useMsal,
	useIsAuthenticated
} from '@azure/msal-react';

import { useTabSettings } from 'components/App/TabSettings';

import {
	WorkZoneAzureProvider,
	useWorkZoneAzureAuthRequest,
	useWorkZoneAzure,
	WorkZoneAzureAuthRequest
} from 'components/WorkZone/WorkZoneAzureProvider';

import LoadingComponent from 'components/WorkZone/Loading';
import ErrorComponent from 'components/WorkZone/Error';

import { joinPath } from 'utils';

import { AUTH_ENDPOINT } from 'components/App/Constants';

const WorkZoneContext = createContext('');

const WorkZoneAuthorizedContext = createContext<
	[boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => void 0]);

export const useWorkZone = () => {
	const [canAuthorize, setCanAuthorize] = useContext(
		WorkZoneAuthorizedContext
	);

	const endpoint = useContext(WorkZoneContext);

	const auth = useWorkZoneAzure();

	useEffect(() => {
		setCanAuthorize(false);
	}, [endpoint, setCanAuthorize]);

	useEffect(() => {
		setCanAuthorize(auth !== null);
	}, [auth, setCanAuthorize]);

	return useMemo(
		() => ({ canAuthorize, endpoint, auth }),
		[canAuthorize, endpoint, auth]
	);
};
/**
 * Provides WorkZone endpoint through context
 */
export function WorkZoneProvider({
	endpoint,
	children
}: React.PropsWithChildren<{ endpoint?: string }>) {
	const authorizedContextValue = useState(false);

	const { server } = useTabSettings();

	const contextValue = typeof endpoint === 'undefined' ? server : endpoint;

	return (
		<WorkZoneAuthorizedContext.Provider value={authorizedContextValue}>
			<WorkZoneContext.Provider value={contextValue}>
				<WorkZoneAzureProvider>
					<MsalProviderFactory>{children}</MsalProviderFactory>
				</WorkZoneAzureProvider>
			</WorkZoneContext.Provider>
		</WorkZoneAuthorizedContext.Provider>
	);
}

function MsalProviderFactory({ children }: React.PropsWithChildren<unknown>) {
	const { auth } = useWorkZone();

	const authenticationRequest = useWorkZoneAzureAuthRequest();

	if (auth && authenticationRequest) {
		// no need for rendering "invalid" provider

		return (
			<MsalProviderInstance auth={auth} request={authenticationRequest}>
				{children}
			</MsalProviderInstance>
		);
	} else {
		return <>{children}</>;
	}
}

export function MsalProviderInstance({
	auth,
	children,
	request
}: React.PropsWithChildren<{
	auth: BrowserAuthOptions;
	request: WorkZoneAzureAuthRequest;
}>) {
	const instance = useMemo(
		() =>
			new PublicClientApplication({
				auth: {
					...auth,
					redirectUri: getRedirectUri('auth')
				},
				cache: {
					// TODO: should these setting be stored somewhere centralized?
					cacheLocation: 'localStorage' // We have to use localStorage to share data between windows
				},
				system: {
					windowHashTimeout: 30000, // Applies just to popup calls - In milliseconds
					iframeHashTimeout: 30000, // Applies just to silent calls - In milliseconds
					loadFrameTimeout: 30000, // Applies to both silent and popup calls - In milliseconds
					allowRedirectInIframe: true /*,
			loggerOptions: {
				loggerCallback: (logLevel, message, containsPii) => console.log(message), // eslint-disable-line
				logLevel: 4
			} */
				}
			}),
		[auth]
	);

	return (
		<MsalProvider instance={instance}>
			<WorkzoneAuthenticate request={request}>
				{children}
			</WorkzoneAuthenticate>
		</MsalProvider>
	);
}

function getRedirectUri(endpoint: string) {
	const paths = window.location.pathname.split('/');

	paths[paths.length - 1] = endpoint;

	return [window.location.origin, paths.join('/')].join('');
}

function useIsWorkZoneAuthenticated() {
	const { inProgress } = useMsal();

	const isAuthenticated = useIsAuthenticated();

	const [isWorkZoneAuthenticated, setWorkZoneAuthenticated] =
		useState<any>(isAuthenticated);

	const { endpoint } = useWorkZone();

	const authenticate = useCallback(
		(
			successCallback: (result?: string) => void,
			failureCallback: (reason?: string | undefined) => void
		) => {
			if (endpoint) {
				window.localStorage.setItem(AUTH_ENDPOINT, endpoint);

				Teams.authentication.initialize();

				Teams.authentication.authenticate({
					url: joinPath(
						window.location.origin,
						process.env.PUBLIC_URL,
						'auth'
					),
					width: BrowserConstants.POPUP_WIDTH,
					height: BrowserConstants.POPUP_HEIGHT,
					successCallback,
					failureCallback
				});
			}
		},
		[endpoint]
	);

	useEffect(() => {
		if (!isAuthenticated && inProgress === InteractionStatus.None) {
			authenticate(
				(result?: string) => {
					setWorkZoneAuthenticated(result || true);
				},
				() => {
					setWorkZoneAuthenticated(false);
				}
			);
		}
	}, [authenticate, inProgress, isAuthenticated]);

	return isWorkZoneAuthenticated;
}

export function WorkzoneAuthenticate({
	children,
	request
}: React.PropsWithChildren<{ request: WorkZoneAzureAuthRequest }>) {
	const isWorkZoneAuthenticated = useIsWorkZoneAuthenticated();

	if (isWorkZoneAuthenticated) {
		const { account: { username } = { username: '' } } =
			isWorkZoneAuthenticated;
		// read token silently, and re-render children
		return (
			<MsalAuthenticationTemplate
				interactionType={InteractionType.Silent}
				authenticationRequest={{
					...request,
					loginHint: username
				}}
				errorComponent={ErrorComponent}
				loadingComponent={LoadingComponent}
			>
				{children}
			</MsalAuthenticationTemplate>
		);
	} else {
		return <>{children}</>;
	}
}
