import { useCallback, ReactNode, useMemo, useRef } from 'react';

import { uuid } from 'utils';

import {
	Box,
	Button,
	Checkbox,
	ChevronEndIcon,
	ChevronStartIcon,
	Divider,
	Flex,
	Grid,
	ListItem
} from '@fluentui/react-northstar';
import {
	ColumnLayout,
	Customizable
} from 'components/Table/utility/getDefaultLayout';

import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { SortableItem } from 'components/Table/SortableItem';

import './ColumnSettingsDialog.css';

export type ColumnItem = {
	header: string;
	content: string;
	media: ReactNode;
	value: ColumnLayout;
	isSelected?: boolean;
	id: string;
};

type ColumnSettingsDialogOptions = {
	availableColumnItems: ColumnItem[];
	availableNewColumnItems: ColumnItem[];
	setAvailableNewColumnItems: (...args: any) => void;
	currentColumnItems: ColumnItem[];
	setCurrentColumnItems: React.Dispatch<React.SetStateAction<ColumnItem[]>>;
	onSaveClicked: () => void;
	onCancelClicked: () => void;
	defaultColumnItems: ColumnItem[];
};

export function ColumnSettingsDialog({
	availableColumnItems,
	availableNewColumnItems,
	setAvailableNewColumnItems,
	currentColumnItems,
	setCurrentColumnItems,
	onSaveClicked,
	onCancelClicked,
	defaultColumnItems
}: ColumnSettingsDialogOptions) {
	const session = useRef(uuid());

	const moveColumnsToCurrentLayout = useCallback(() => {
		const selectedItems = availableNewColumnItems?.filter(
			item => item.isSelected
		);
		if (selectedItems) {
			const newCurrentColumnItems = [
				...currentColumnItems,
				...selectedItems
			];
			setAvailableNewColumnItems(
				availableColumnItems
					.filter(
						(column: ColumnItem) =>
							!newCurrentColumnItems.some(x => x.id === column.id)
					)
					.map(item => cloneColumnItem(item))
			);
			selectedItems.forEach(item => (item.isSelected = false));
			setCurrentColumnItems(newCurrentColumnItems);
		}
	}, [
		availableColumnItems,
		availableNewColumnItems,
		currentColumnItems,
		setAvailableNewColumnItems,
		setCurrentColumnItems
	]);

	const removeColumnsFromCurrentLayout = useCallback(() => {
		const selectedItems = currentColumnItems?.filter(
			item => item.isSelected
		);
		if (selectedItems) {
			const newCurrentColumnItems = currentColumnItems?.filter(
				item => !item.isSelected
			);
			setAvailableNewColumnItems(
				availableColumnItems
					.filter(
						(column: ColumnItem) =>
							!newCurrentColumnItems.some(x => x.id === column.id)
					)
					.map(item => cloneColumnItem(item))
			);
			setCurrentColumnItems(newCurrentColumnItems);
		}
	}, [
		availableColumnItems,
		currentColumnItems,
		setAvailableNewColumnItems,
		setCurrentColumnItems
	]);

	const resetColumnSettings = useCallback(() => {
		// force children re-render
		session.current = uuid();

		const customizableItems = defaultColumnItems
			.filter(
				column => column.value.customizable !== Customizable.readonly
			)
			.map(item => cloneColumnItem(item));
		const newAvailableColumns = availableColumnItems
			?.filter(
				(column: ColumnItem) =>
					!customizableItems.some(x => x.id === column.id)
			)
			.map(item => cloneColumnItem(item));

		setCurrentColumnItems([...customizableItems]);
		setAvailableNewColumnItems([...newAvailableColumns]);
	}, [
		availableColumnItems,
		defaultColumnItems,
		setAvailableNewColumnItems,
		setCurrentColumnItems
	]);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 1
			}
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	);

	function handleDragEnd(event: any) {
		const { active, over } = event;

		if (active.id !== over.id) {
			setCurrentColumnItems(items => {
				if (items) {
					const itemIds = items.map(item => item.id);
					const oldIndex = itemIds.indexOf(active.id);
					const newIndex = itemIds.indexOf(over.id);

					return arrayMove(items, oldIndex, newIndex);
				}
				return items;
			});
		}
	}

	const selectedColumns = useMemo(() => {
		return currentColumnItems?.map((item, idx) => (
			<SortableItem
				key={`${session.current}-${item.id}`}
				index={idx}
				item={item}
			/>
		));
	}, [currentColumnItems]);

	const availableColumns = useMemo(() => {
		return availableNewColumnItems?.map((item, idx) => (
			<ListItem
				key={`${session.current}-${item.id}`}
				index={idx}
				header={item.header}
				content={item.content}
				media={item.media}
			/>
		));
	}, [availableNewColumnItems]);

	const gridStyles = useMemo(
		() => ({
			margin: '0',
			padding: '0',
			justifyContent: 'normal',
			border: '1px solid',
			borderColor: 'lightgray'
		}),
		[]
	);

	const headerStyles = useMemo(
		() => ({
			padding: '1.25rem',
			fontStyle: 'italic',
			textAlign: 'center',
			fontSize: 'medium'
		}),
		[]
	);

	const listStyles = useMemo(
		() => ({
			padding: '2px',
			margin: '5px',
			maxHeight: '400px',
			overflowY: 'scroll'
		}),
		[]
	);

	return (
		<Box>
			<Grid columns="1fr auto 1fr">
				<Grid rows="auto auto 90%" styles={gridStyles}>
					<Box styles={headerStyles}>Available columns</Box>
					<Divider />
					<Box styles={listStyles}>{availableColumns}</Box>
				</Grid>
				<Grid rows="40% 20% 40%">
					<br />
					<Flex
						column
						gap="gap.small"
						styles={{ textAlign: 'center', padding: '6px' }}
					>
						<Button
							aria-label="moveColumnsToCurrentLayout"
							iconOnly
							onClick={moveColumnsToCurrentLayout}
						>
							<ChevronEndIcon />
						</Button>
						<Button
							iconOnly
							aria-label="removeColumnsFromCurrentLayout"
							onClick={removeColumnsFromCurrentLayout}
						>
							<ChevronStartIcon />
						</Button>
					</Flex>
					<br />
				</Grid>
				<Grid rows="auto auto 90%" styles={gridStyles}>
					<Box styles={headerStyles}>Current columns</Box>
					<Divider />
					<Box styles={listStyles}>
						<DndContext
							sensors={sensors}
							collisionDetection={closestCenter}
							onDragEnd={handleDragEnd}
						>
							<SortableContext
								items={currentColumnItems ?? []}
								strategy={verticalListSortingStrategy}
							>
								{selectedColumns}
							</SortableContext>
						</DndContext>
					</Box>
				</Grid>
			</Grid>
			<br />
			<Flex space="between">
				<Button secondary onClick={resetColumnSettings}>
					Reset
				</Button>
				<Flex gap="gap.small">
					<Button primary onClick={onSaveClicked}>
						Save
					</Button>
					<Button secondary onClick={onCancelClicked}>
						Cancel
					</Button>
				</Flex>
			</Flex>
		</Box>
	);
}

export function layoutColumnsToColumnItems(currentColumns: {
	[column: string]: ColumnLayout;
}) {
	return Object.entries<ColumnLayout>(currentColumns).map<ColumnItem>(
		entry => {
			const item: ColumnItem = {
				header: entry[1]?.label?.toString() ?? entry[0],
				media: (
					<Checkbox
						disabled={entry[1].customizable !== Customizable.full}
						styles={{ marginRight: '-10px' }}
						onChange={(...props) => {
							item.isSelected = props?.[1]?.checked ?? false;
						}}
					/>
				),
				value: entry[1],
				isSelected: false,
				content: entry[0],
				id: entry[0]
			};
			return item;
		}
	);
}

export function cloneColumnItem(column: ColumnItem) {
	const newColumnItem = {
		...column,
		value: JSON.parse(JSON.stringify(column.value))
	};
	newColumnItem.media = (
		<Checkbox
			disabled={newColumnItem.value.customizable !== Customizable.full}
			styles={{ marginRight: '-10px' }}
			onChange={(...props) => {
				newColumnItem.isSelected = props?.[1]?.checked ?? false;
			}}
		/>
	);
	return newColumnItem;
}
