import Constants from 'components/WorkZone/Odata/Constants';
import Modifiers from 'components/WorkZone/Odata/Modifiers';

function castArray(value: [] | any = []) {
	return Array.isArray(value) ? value : [value];
}

type ODataQuerySelect = {
	select?: string | string[];
};

type ODataQueryFilter = {
	filter?: string;
};

type ODataQueryOrder = {
	orderby?: string;
};

type ODataQueryTop = {
	top?: 'all' | number;
};

type ODataQueryGenericCollection = {
	[key: string]: string | string[];
};

type ODataQueryCollection = ODataQueryGenericCollection &
	ODataQuerySelect &
	ODataQueryFilter &
	ODataQueryOrder &
	ODataQueryTop;

export type ODataQuery =
	| string
	| {
			[collection: string]: ODataQueryCollection;
	  };

const UrlBuilder = {
	/**
	 * Converts a literal query object into Odata query string
	 * @param {Object<>|string} query
	 * @returns {string}
	 */
	queryToUrl(query: ODataQuery = '') {
		if (typeof query === 'string') {
			return Modifiers.encoders.ensure(query);
		}

		const queryParts: string[] = [],
			collection = Object.keys(query)[0];

		if (collection) {
			queryParts.push(Modifiers.encoders.ensure(collection));

			const keys = Object.keys(query[collection]),
				values = { ...query[collection] }, // make copy and don't mutate original query
				expanders: string[] = [];

			Modifiers.process(collection, keys, values);

			this.processKeys(keys, values, expanders, queryParts);
		} else {
			return query;
		}

		return queryParts.join('');
	},
	/**
	 * Query keys processing
	 * @param {string[]} keys array of query keys to process
	 * @param {object} values copy of query collection value
	 * @param {string[]} expanders storage array for extracted expanders
	 * @param {string[]} queryParts storage array for query parts
	 */
	processKeys(
		keys: string[],
		values: ODataQueryCollection,
		expanders: string[],
		queryParts: string[]
	) {
		if (keys.length) {
			queryParts.push('?');

			const queryParamParts: string[] = [];

			Object.values(keys).forEach(key => {
				const paramParts: string[] = [];

				const isSkipped = this.processNativeParams(
					key,
					values,
					expanders,
					queryParamParts,
					paramParts
				);

				if (!isSkipped) {
					const pair = this.preparePair(key, values[key]);
					// empty values have no meaning in the query
					if (pair) {
						paramParts.push(pair);

						queryParamParts.push(paramParts.join(''));
					}
				}
			});

			if (expanders.length) {
				const pair = this.preparePair('$expand', expanders);

				if (pair) {
					queryParamParts.push(pair);
				}
			}

			queryParts.push(queryParamParts.join('&'));
		}
	},
	/**
	 * Converts query object into URL part of the query
	 * @param {string} key query key
	 * @param {object} values query object
	 * @param {string[]} expanders storage array of expanders to push to
	 * @param {string[]} queryParamParts storage array of parameter parts to push to
	 * @param {string[]} paramParts storage array to push query parts to push to
	 */
	processNativeParams(
		key: string,
		values: ODataQueryCollection,
		expanders: string[],
		queryParamParts: string[],
		paramParts: string[]
	) {
		if (Constants.nativeParams.includes(key)) {
			paramParts.push('$');

			switch (key) {
				case 'select': {
					return this.processSelect(key, values, expanders);
				}

				case 'top': {
					// don't use server-driven paging
					return this.processTop(key, values, queryParamParts);
				}

				case 'skip': {
					if (+values[key] === 0) {
						// no need for skip 0
						return true;
					}
				}

				// no default
			}
		}
	},

	processSelect(
		key: string,
		values: ODataQueryCollection,
		expanders: string[]
	) {
		this.extractExpanders(values[key], expanders);
	},

	processTop(
		key: string,
		values: ODataQueryCollection,
		queryParamParts: string[]
	) {
		if (+values[key] === Constants.serverPageSize) {
			// skip paging by 50, since it is a default page size
			return true;
		}

		if (values[key] === 'all') {
			values[key] = String(Constants.maxPageSize);
		}

		if (`${values[key]}` !== Constants.precreateTopValue) {
			const pair = this.preparePair('pageSize', values[key]);

			if (pair) {
				queryParamParts.push(pair);
			}
		}
	},
	/**
	 * Extracts values for the $expand parameter for the given $select values
	 * @param {Array<string>|string} selects
	 * @param {Array<string>} expanders set of existing expanders to put new into
	 */
	extractExpanders(selects: string[] | string, expanders: string[]) {
		if (Array.isArray(selects)) {
			selects.forEach(select => {
				const paths = select.split('/');

				if (paths.length > 1) {
					paths.pop();

					const expander = paths.join('/');

					if (!expanders.includes(expander)) {
						expanders.push(expander);
					}
				}
			});
		} else {
			this.extractExpanders(selects.split(','), expanders);
		}
	},
	/**
	 * Creates a query param=value pair, encoding value if needed
	 * @param {string} param
	 * @param {Array<string|number>|string|number} value
	 * @returns {string}
	 */
	preparePair(param: string, value: string[] | string) {
		const pair = [param, castArray(value).join(',')];

		if (typeof Modifiers.encoders[param] === 'function') {
			// encode values
			pair[1] = Modifiers.encoders[param](pair[1]);
		}

		return pair[1] ? pair.join('=') : null;
	},
	/**
	 * Creates aggregation parameters to add to the query
	 * @param {string} using Aggregate function name
	 * @param {string} by Property to aggregate on
	 * @returns {object}
	 */
	aggregate(using: string, by: string) {
		if (using && by) {
			return {
				aggregate: `${using}(${Modifiers.encoders.ensure(by)})`,
				ignoreCount: true
			};
		}

		return {};
	}
};

export default UrlBuilder;
