import { readProperty } from 'utils';
import { ListLayout } from './getDefaultLayout';
import { TableData, TableDataEntry, TableRowCell, TableRowData } from 'types';

export function convertRowDataToRow(
	layout: ListLayout,
	data: TableDataEntry,
	key: string
): TableRowData {
	const columns = Object.keys(layout.columns);

	const cells = columns.reduce(
		(acc: { [index: string]: TableRowCell }, name) => {
			acc[name] = {
				key: `${key}-${layout.columns[name].order}`,
				truncateContent: true,
				content: null
			};

			if (typeof layout.columns[name].value === 'function') {
				const valueFunction = layout.columns[name].value as (
					...args: any
				) => React.ReactNode;

				acc[name].content = valueFunction(data);
			} else {
				acc[name].content = readProperty(name, data);
			}

			return acc;
		},
		{}
	);

	return {
		key: key,
		id: data.ID,
		cells,
		data: data
	};
}

function convertDataToRows(
	layout: ListLayout,
	data: TableData,
	page = 0
): TableRowData[] {
	return data.value
		? data.value.map((item: TableDataEntry, index: number) => {
				return convertRowDataToRow(layout, item, `${page}-${index}`);
		  })
		: [];
}

function convertPageDataToRows(layout: ListLayout, data: any): TableRowData[] {
	return data.pages
		? data.pages.reduce(
				(acc: TableRowData[], page: TableData, pageNumber: number) => {
					const pageRows = convertDataToRows(
						layout,
						page,
						pageNumber
					);

					return [...acc, ...pageRows];
				},
				[]
		  )
		: [];
}

export default convertPageDataToRows;
