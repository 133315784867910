import {
	useActionableContext,
	useEditDialogContext
} from 'components/Table/ActionableTable';
import { handleRecordEditErrors } from 'components/Table/CollectionProperties/Record';
import { TableRowData } from 'types';
import {
	useOdataRemoveMutationInQuery,
	useRequestOptions
} from 'components/WorkZone/Odata/Odata';
import { useCallback } from 'react';
import Notification from 'components/Notification';
import Form from 'components/Form';

export function Recycle({ row }: { row: TableRowData }) {
	const { source } = useActionableContext();
	const requestOptions = useRequestOptions();
	const { setDialogOpen } = useEditDialogContext();

	const recordMutation = useOdataRemoveMutationInQuery({
		collection: 'Records',
		id: row.id,
		key: source?.key,
		select: source?.source.getProperties(),
		updateSupplementaryitems: true,
		childParentRefPropName: 'PrimaryRecordKey_Value',
		parentRefPropName: 'RecordKey'
	});

	const saveAction = useCallback(async () => {
		try {
			const result = await recordMutation.mutateAsync({
				ID: row.id,
				ThrashedBy_Value: '@Me',
				'odata.type': 'Som.Record'
			} as any);

			if (typeof result['odata.error'] !== 'undefined') {
				await handleRecordEditErrors(result, row, requestOptions);
				return false;
			}

			return true;
		} catch (e: any) {
			Notification.error(e.message);
			return false;
		}
	}, [recordMutation, requestOptions, row]);

	const onSubmit = useCallback(
		async e => {
			if (await saveAction()) {
				setDialogOpen(false);
			}
		},
		[saveAction, setDialogOpen]
	);

	return (
		<Form
			name="Records.Recycle"
			onSubmit={onSubmit}
			onCancel={() => setDialogOpen(false)}
		/>
	);
}
