import {
	useMemo,
	useState,
	useCallback,
	createContext,
	useContext
} from 'react';
import { Flex, Text, Loader } from '@fluentui/react-northstar';
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useAccount,
	useMsal
} from '@azure/msal-react';
import * as Teams from '@microsoft/teams-js';

import { WorkZone } from 'components/WorkZone';

import { useTabSettings } from 'components/App/TabSettings';
import { useOdata } from 'components/WorkZone/Odata/Odata';
import { joinPath } from 'utils';

import Content from 'components/Content/Content';
import Toolbar from 'components/Toolbar/Toolbar';
import Table from 'components/Table/Table';
import { useTable } from 'hook';
import Uploader from 'components/Uploader/Uploader';
import { ToastContainer } from 'react-toastify';
import { ListLayout } from './Table/utility/getDefaultLayout';
import { GetConfigFromStorage } from './Table/LocalStorageHandler';
import { EditDialogContext } from './Table/ActionableTable';

export function useEditDialogContext() {
	const context = useContext(EditDialogContext);

	if (typeof context === 'undefined') {
		throw new Error('useEditDialogContext must be used within a <Table />');
	}

	return context;
}

export const ColumnSettingsContext = createContext<
	React.Dispatch<
		React.SetStateAction<
			| {
					[collection: string]: ListLayout;
			  }
			| undefined
		>
	>
>(() => {
	/* */
});

export default function WorkZoneTab() {
	return (
		<WorkZone>
			<ToastContainer />
			<Tab />
		</WorkZone>
	);
}

function Tab() {
	const [onUploadingComplete, refreshList] = useState(false);

	const { fileId } = useTabSettings();

	const [columnSettings, setColumnSettings] =
		useState<{ [collection: string]: ListLayout }>();

	const getSettings = useCallback(settings => {
		const contentUrl = new URL(settings.contentUrl),
			query = new URLSearchParams(contentUrl.search),
			tabId = query.get('tabId');
		if (tabId) {
			setColumnSettings(GetConfigFromStorage(tabId));
		}
	}, []);

	useMemo(() => {
		Teams.initialize(() => {
			Teams.settings.getSettings(getSettings);
		});
	}, [getSettings]);

	const [orderBy, setOrderBy] = useState('ID');

	const onSort = useCallback((order: string) => {
		setOrderBy(order);
	}, []);

	const options = useMemo(
		() => ({
			collection: 'Records',
			filter: `FileKey_Value eq '${fileId}'`,
			orderby: orderBy
		}),
		[fileId, orderBy]
	);

	const fullHeight = {
		height: '100vh'
	};

	const onUpload = useCallback(() => {
		// trig table reload
		refreshList(true);
		// reset state back, so consequent uploads work too
		refreshList(false);
	}, []);

	const { source } = useTable(options, columnSettings);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogName, setDialogName] = useState('');
	const [dialogContent, setDialogContent] = useState<React.ReactNode>(null);
	const [dialogFooter, setDialogFooter] = useState<React.ReactNode>(null);
	const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

	const setDialogOpenWrapped = useCallback((open = false) => {
		if (!open) {
			// reset dialog content
			setDialogContent(null);
		}

		return setDialogOpen(open);
	}, []);

	const contextValue = useMemo(
		() => ({
			dialogOpen,
			dialogName,
			dialogContent,
			dialogFooter,
			isRefreshing,
			setDialogName,
			setDialogOpen: setDialogOpenWrapped,
			setDialogContent,
			setDialogFooter,
			setIsRefreshing
		}),
		[
			dialogContent,
			dialogFooter,
			dialogName,
			dialogOpen,
			isRefreshing,
			setDialogOpenWrapped
		]
	);

	return (
		<ColumnSettingsContext.Provider value={setColumnSettings}>
			<EditDialogContext.Provider value={contextValue}>
				<Flex column style={fullHeight}>
					<AuthenticatedTemplate>
						<Uploader
							flex
							column
							style={fullHeight}
							onUpload={onUpload}
						>
							<Toolbar source={source} />
							<TableHeader />
							{fileId ? (
								<Table
									flex
									options={options}
									force={onUploadingComplete}
									customLayout={columnSettings}
									onSort={onSort}
								/>
							) : (
								<TabLoader />
							)}
						</Uploader>
					</AuthenticatedTemplate>
					<UnauthenticatedTemplate>
						<TabLoader />
					</UnauthenticatedTemplate>
				</Flex>
			</EditDialogContext.Provider>
		</ColumnSettingsContext.Provider>
	);
}

function TableHeader() {
	return (
		<Flex space="between" style={{ padding: '0 15px 15px 15px' }}>
			<CaseInfo />
			<UserInfo />
		</Flex>
	);
}

function TabLoader() {
	return (
		<Content center>
			<Loader />
		</Content>
	);
}

function CaseInfo() {
	const { server, fileId } = useTabSettings();

	const { data: file } = useOdata(
		{
			[`Files('${fileId}')`]: {
				select: 'Summary'
			}
		},
		{
			enabled: Boolean(fileId)
		}
	);

	const href = joinPath(server, 'app/client/#/Files', fileId);

	const style = { textDecoration: 'none' };

	return (
		<Text size="large">
			Documents from{' '}
			{file ? (
				<Text
					color="brand"
					as="a"
					target="_blank"
					rel="noreferrer"
					href={href}
					style={style}
				>
					{file.Summary}
				</Text>
			) : (
				<>...</>
			)}
		</Text>
	);
}

function UserInfo() {
	const { accounts } = useMsal();

	const account = useAccount(accounts[0] || {});

	return (
		<Text size="small" align="end">
			Signed in as {account?.name} ({account?.username})
		</Text>
	);
}
