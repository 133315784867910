import { useEffect, useState } from 'react';

import { useOdata } from 'components/WorkZone/Odata/Odata';
import { getSchemeByExtension } from 'components/Document/Scheme';
import { download } from 'components/Table/CollectionActions/Record/OpenByUri';

export function useCreateDocument(fileId: string | number) {
	const [extension, setExtension] = useState<any>(null);

	const { data } = useOdata(`GetWebDAVFolderPath?fileID='${fileId}'`, {
		enabled: Boolean(fileId)
	});

	useEffect(() => {
		if (data && extension) {
			const path = data.value;

			const scheme = getSchemeByExtension(extension);

			setExtension(null);

			try {
				download(
					`${scheme}:nft|u|${path}/WZfOTemplateRedirect.${extension}|s|${path}`
				);
			} catch {
				/* silently */
			}
		}
	}, [extension, data]);

	return { create: setExtension };
}
