import { ControlType } from 'components/Form/controls';

import Layout from './Layout';

type BaseControlLayout = {
	type: ControlType;
	label: string;
	order: string;
	required?: boolean;
	disabled?: boolean;
};

export type TextControlLayout = BaseControlLayout & {
	content?: string;
};

export type TextBoxControlLayout = BaseControlLayout & {
	placeholder?: string;
	max?: number;
};

export type DropdownItem = {
	id: string;
	header: string;
	content?: any;
};

export type DropdownControlLayout = BaseControlLayout & {
	domain?: string;
	items?: DropdownItem[];
	placeholder?: string;
};

type ControlLayout =
	| TextControlLayout
	| TextBoxControlLayout
	| DropdownControlLayout;

type FormButtonType = 'submit' | 'cancel' | string;

type FormButtonAlignType = 'left' | undefined;

export type FormButtonLayout = {
	role: FormButtonType;
	label: string;
	enabled?: boolean;
	align?: FormButtonAlignType;
	actionInProgress?: boolean;
	isFormWithoutInputs?: boolean;
	submitAllowed?: boolean;
};

export type FormLayout = {
	controls: {
		[key: string]: ControlLayout;
	};
	buttons?: FormButtonLayout[];
};

export type FormLayoutByName = {
	[key: string]: FormLayout;
};

export function getLayoutByName(name: string): FormLayout {
	const path = name.split('.');

	return path.reduce((acc: any, part: string) => {
		if (acc[part]) {
			return acc[part];
		}

		return {};
	}, Layout);
}
