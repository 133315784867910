import { ExpandIcon } from '@fluentui/react-icons-northstar';
import { Dispatch, SetStateAction, useState, useCallback } from 'react';
import Draggable from 'react-draggable';

function ResizeColumnIcon({
	resizeHandler,
	setIsResizing,
	style,
	columnClassName
}: {
	resizeHandler?: (param: number) => void;
	setIsResizing: Dispatch<SetStateAction<boolean>>;
	style?: React.CSSProperties;
	columnClassName: string;
}) {
	const [inDragging, setInDragging] = useState<boolean>(false);

	const onStart = useCallback(() => {
		setIsResizing(true);
		setInDragging(true);
		document.documentElement.style.cursor = 'col-resize';
	}, [setIsResizing, setInDragging]);

	const onStop = useCallback(
		(_event, data) => {
			resizeHandler && resizeHandler(data.x * 10000);
			setIsResizing(false);
			setInDragging(false);
			document.documentElement.style.cursor = 'default';
		},
		[resizeHandler, setIsResizing, setInDragging]
	);

	const onDrag = useCallback(
		event => {
			const cells = document.getElementsByClassName(
				columnClassName
			) as HTMLCollectionOf<HTMLElement>;

			const rootElement = document.getElementById('root');

			for (const cell of cells) {
				const offsetRight =
					event.pageX -
					cell.getBoundingClientRect().left -
					cell.offsetWidth;

				if (cell && rootElement) {
					cell.style.maxWidth = `${
						rootElement.scrollLeft + cell.offsetWidth + offsetRight
					}px`;
					cell.style.minWidth = `${
						rootElement.scrollLeft + cell.offsetWidth + offsetRight
					}px`;
				}
			}
		},
		[columnClassName]
	);

	return (
		<>
			<Draggable
				axis="x"
				defaultClassName="DragHandle"
				defaultClassNameDragging="DragHandleActive"
				position={{ x: 0, y: 0 }}
				onStop={onStop}
				onStart={onStart}
				onDrag={onDrag}
				scale={10000}
			>
				<ExpandIcon
					style={{
						...style,
						zIndex: 99,
						cursor: 'col-resize'
					}}
					rotate={45}
					size="smaller"
				/>
			</Draggable>
			{inDragging && (
				<div
					style={{
						height: '100%',
						width: 1,
						position: 'absolute',
						right: 0,
						backgroundColor: 'rgba(134, 134, 138, 0.5)',
						boxShadow: '0px 0px 5px 1px rgba(134, 134, 138, 0.5)'
					}}
				/>
			)}
		</>
	);
}

export default ResizeColumnIcon;
