import { Box, Tooltip } from '@fluentui/react-northstar';
import { useTabSettings } from 'components/App/TabSettings';
import { getSchemeByExtension } from 'components/Document/Scheme';
import { TableRowData } from 'types';
import { useCallback } from 'react';
import { getWorkZoneLink } from 'utils';
import { getDocumentLink, openByUriScheme } from './OpenByUri';

import './OpenInWorkZoneInline.css';

export function OpenInWorkZoneInline({
	row,
	label,
	hasTooltip
}: {
	row: TableRowData;
	label?: string;
	hasTooltip?: boolean;
}) {
	const { server } = useTabSettings();

	const checkOfficeDocument = (rowData: TableRowData): boolean => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [_link, extension] = getDocumentLink(rowData);
		const scheme = getSchemeByExtension(extension);
		return Boolean(scheme);
	};

	const onClickHandler = useCallback(() => {
		const isOfficeFile = checkOfficeDocument(row);
		isOfficeFile
			? openByUriScheme(row)
			: window.open(getWorkZoneLink(server, row), '_blank', 'noreferrer');
	}, [row, server]);

	if (!label) {
		return null;
	}

	return hasTooltip ? (
		<Tooltip
			content={label}
			trigger={
				<Box onClick={onClickHandler} className="title-link">
					{label}
				</Box>
			}
		/>
	) : (
		<Box onClick={onClickHandler} className="title-link">
			{label}
		</Box>
	);
}
