import {
	Accessibility,
	Dialog,
	Table,
	TableBehaviorProps
} from '@fluentui/react-northstar';
import { createContext, useContext, useMemo } from 'react';
import Draggable from 'react-draggable';

import { collectionHasActions } from './Actions';
import type { InfiniteSource } from 'hook';
import { TableRowData } from 'types';

export const EditDialogContext = createContext<
	EditDialogContextValues | undefined
>(undefined);

export function useEditDialogContext() {
	const context = useContext(EditDialogContext);

	if (typeof context === 'undefined') {
		throw new Error(
			'useEditDialogContext must be used within a <EditDialog /> within <ActionableTable />'
		);
	}

	return context;
}

type EditDialogContextValues = {
	dialogOpen: boolean;
	dialogName: string;
	dialogContent: React.ReactNode;
	dialogFooter: React.ReactNode;
	isRefreshing: boolean;
	setDialogName: React.Dispatch<React.SetStateAction<string>>;
	setDialogOpen: (open: boolean) => void;
	setDialogContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
	setDialogFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
	setIsRefreshing: React.Dispatch<React.SetStateAction<boolean>>;
};

type ActionableTableProps = React.PropsWithChildren<{
	accessibility: Accessibility<TableBehaviorProps>;
	count: number | undefined;
	style?: React.CSSProperties;
	collection: string;
	rows: TableRowData[];
	source: InfiniteSource;
}>;

const ActionableContext = createContext<{
	rows: TableRowData[];
	source: InfiniteSource | null;
}>({ rows: [], source: null });

export function useActionableContext() {
	return useContext(ActionableContext);
}

export function ActionableTable({
	children,
	accessibility,
	count,
	style,
	collection,
	rows,
	source
}: ActionableTableProps) {
	if (collectionHasActions(collection)) {
		const contextValue = { rows, source };

		return (
			<ActionableContext.Provider value={contextValue}>
				<Table
					accessibility={accessibility}
					aria-rowcount={count}
					style={style}
				>
					<EditDialog>{children}</EditDialog>
				</Table>
			</ActionableContext.Provider>
		);
	}

	return (
		<Table
			accessibility={accessibility}
			aria-rowcount={count}
			style={style}
		>
			{children}
		</Table>
	);
}

export function EditDialog({ children }: React.PropsWithChildren<unknown>) {
	const {
		dialogOpen,
		dialogName,
		dialogContent,
		dialogFooter,
		setDialogFooter
	} = useEditDialogContext();

	const headerStyles = useMemo(
		() => ({
			width: '40vw',
			'text-overflow': 'ellipsis',
			'white-space': 'nowrap',
			overflow: 'hidden'
		}),
		[]
	);

	useMemo(() => {
		if (!dialogOpen) {
			setDialogFooter(null);
		}
	}, [dialogOpen]);

	return (
		<>
			<Draggable handle=".drag-handle">
				<Dialog
					styles={{
						[`@media screen and (min-width: 800px )`]: {
							width: '920px'
						}
					}}
					header={{
						styles: headerStyles,
						content: dialogName,
						className: 'drag-handle'
					}}
					open={dialogOpen}
					content={dialogContent}
					footer={dialogFooter}
				/>
			</Draggable>
			{children}
		</>
	);
}
