import { Alert } from '@fluentui/react-northstar';
import { ShieldKeyholeIcon } from '@fluentui/react-icons-northstar';

export default function LoadingComponent() {
	return (
		<Alert
			icon={<ShieldKeyholeIcon />}
			content="Authentication is in progress..."
		/>
	);
}
