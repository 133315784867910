import { getSchemeByExtension } from 'components/Document/Scheme';
import Notification from 'components/Notification';
import { TableRowData } from 'types';
import OData from 'components/WorkZone/Odata/Odata';
import { DateTime } from 'luxon';

export function isRecordProtected({ data }: TableRowData) {
	return !data.HasWritePermission;
}

export function isRecordOnClosedCase({ data }: TableRowData) {
	return !!data.File?.Closed;
}

export function isRecordTrashed({ data }: TableRowData) {
	return !!data.ThrashedBy_Value;
}

export function hasScheme({ data }: TableRowData) {
	return !!getSchemeByExtension(data.Document?.Extension);
}

export function isLogEntry({ data }: TableRowData) {
	return ['DEL', 'OMJ'].includes(data.RecordType_Value);
}

export function isJournalized({ data }: TableRowData) {
	return +data.RecordNo > 0;
}

export function canBeRecycled(row: TableRowData) {
	return !isRecordProtected(row) && !isRecordTrashed(row) && !isLogEntry(row);
}

export async function handleRecordEditErrors(
	result: any,
	row: TableRowData,
	requestOptions: any
) {
	if (result['odata.error'].code === 'USERMSG_DOCUMENT_EDIT_DELETE.1;') {
		const recordSyncProps = await OData.request(
			{
				[`Records('${row.id}')`]: {
					select: [
						'CheckoutUser_Value',
						'CheckoutUser_Summary',
						'CheckoutDate',
						'CheckoutNote'
					]
				}
			},
			requestOptions
		);
		Notification.warn(
			`Cannot delete record cause it is checked out by: ${
				recordSyncProps.CheckoutUser_Summary ||
				recordSyncProps.CheckoutUser_Value ||
				'unknown'
			}
										 Checkout date: ${
												recordSyncProps.CheckoutDate
													? DateTime.fromISO(
															recordSyncProps.CheckoutDate
													  )
															?.toLocal()
															?.toLocaleString(
																DateTime.DATETIME_FULL
															)
													: 'unknown'
											}
										 Note: ${recordSyncProps.CheckoutNote || 'unknown'}`
		);
	} else {
		Notification.error(result['odata.error'].message.value);
	}
}
