import { gridCellBehavior, Table } from '@fluentui/react-northstar';
import { useMemo } from 'react';

import { ExtendedComponentDesignProp, TableRowCell } from 'types';
import CellLoading from './CellLoading';
import { ActionableCell } from './ColumnContextMenu';
import { ColumnLayout } from './utility/getDefaultLayout';

const ColumnAlignMap = {
	center: 'center',
	left: 'flex-start',
	right: 'flex-end'
};

type TableBodyCell = {
	name: string;
	layout: ColumnLayout;
};

interface BodyCellProps {
	column: TableBodyCell;
	cell?: TableRowCell;
	actions?: any;
	rowindex: number;
	dataColumIndex?: number;
	columnClassName: string;
	isMoreOptionsButtonVisible?: boolean;
}

function BodyCell({
	column,
	cell,
	actions,
	rowindex,
	dataColumIndex,
	columnClassName,
	isMoreOptionsButtonVisible
}: BodyCellProps) {
	const cells = document.getElementsByClassName(
		columnClassName
	) as HTMLCollectionOf<HTMLElement>;

	const design = useMemo(() => {
		const { layout } = column;

		const designProp: ExtendedComponentDesignProp = {};

		if (typeof layout.width === 'number') {
			designProp.maxWidth = `${layout.width}px`;
			designProp.minWidth = `${layout.width}px`;
		}

		if (layout.align) {
			designProp.justifyContent = ColumnAlignMap[layout.align];
			if (layout.align === 'right') {
				designProp.position = 'absolute';
				designProp.right = '0px';
			}
		}

		return designProp;
	}, [column]);

	if (cell) {
		if (actions) {
			return (
				<ActionableCell
					cell={cell}
					actions={actions}
					rowindex={rowindex}
					isActionsColumn={column.name === 'action'}
					isMoreOptionsButtonVisible={isMoreOptionsButtonVisible}
					trigger={(props: any) => {
						return (
							<Table.Cell
								{...props}
								accessibility={gridCellBehavior}
								design={design}
								className={`column-index-${dataColumIndex}`}
								style={{
									width:
										column.layout.width === 'fit-content'
											? cells[0].offsetWidth
											: undefined,
									maxWidth:
										column.layout.width === 'fit-content'
											? cells[0].offsetWidth
											: undefined,
									minWidth:
										column.layout.width === 'fit-content'
											? cells[0].offsetWidth
											: undefined
								}}
							/>
						);
					}}
				/>
			);
		} else {
			return (
				<Table.Cell
					{...cell}
					accessibility={gridCellBehavior}
					design={design}
					className={`column-index-${dataColumIndex}`}
					style={{
						width:
							column.layout.width === 'fit-content'
								? cells[0].offsetWidth
								: undefined,
						maxWidth:
							column.layout.width === 'fit-content'
								? cells[0].offsetWidth
								: undefined,
						minWidth:
							column.layout.width === 'fit-content'
								? cells[0].offsetWidth
								: undefined
					}}
				/>
			);
		}
	} else {
		return <CellLoading column={column} />;
	}
}

export default BodyCell;
