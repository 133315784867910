import { getIcon } from 'components/Table/Modifiers';

export enum ColumnAlign {
	center = 'center',
	left = 'left',
	right = 'right'
}

export enum Customizable {
	readonly = 'readonly',
	orderonly = 'orderonly',
	full = 'full'
}

export enum ColumnSort {
	asc = 'asc',
	desc = 'desc'
}

type ComplexLabel = {
	value: (...args: any) => React.ReactNode;
	title?: string;
	align?: ColumnAlign;
};

export type ColumnLayout = {
	width: number | 'auto' | 'fit-content';
	order: number;
	exists?: boolean;
	label?: string | ComplexLabel;
	value?: (...args: any) => React.ReactNode;
	align?: ColumnAlign;
	customizable: string;
	sort?: ColumnSort;
	isDefault?: boolean;
};

export type ListLayout = {
	columns: { [column: string]: ColumnLayout };
	data?: string[];
};

function getDefaultLayout(): { [collection: string]: ListLayout } {
	return {
		Records: {
			columns: {
				icon: {
					exists: false,
					width: 50,
					order: 0,
					label: 'Type',
					value: (record: any) => getIcon(record.Document?.Extension),
					customizable: Customizable.readonly
				},
				ID: {
					width: 80,
					order: 1,
					label: 'ID',
					customizable: Customizable.full
				},
				Title: {
					isDefault: true,
					width: 450,
					order: 2,
					label: 'Title',
					customizable: Customizable.orderonly
				},
				State_Summary: {
					width: 160,
					order: 3,
					label: 'State',
					customizable: Customizable.full
				},
				action: {
					exists: false,
					width: 5,
					align: ColumnAlign.right,
					order: 9999999,
					label: '',
					customizable: Customizable.readonly
				}
			},
			data: [
				'Locked',
				'State_Value',
				'File/Closed',
				'HasWritePermission',
				'ThrashedBy_Value',
				'DocumentFormat/Kind_Value',
				'Document/Extension',
				'RecordType_Value',
				'RecordNo',
				'RetentionPolicy/RetentionPolicy/CommentRequired',
				'PrimaryRecordKey_Value',
				'RecordKey'
			]
		}
	};
}

export default getDefaultLayout;
