import { useCallback, useContext } from 'react';
import { Button, SyncIcon, Text } from '@fluentui/react-northstar';
import { EditDialogContext } from 'components/Table/ActionableTable';
import type { InfiniteSource } from 'hook';

export function RefreshListAction({
	source,
	title
}: {
	source: InfiniteSource;
	title: string;
}) {
	const { refetch } = source;
	const EditDialogContextObj = useContext(EditDialogContext);

	const onClick = useCallback(async () => {
		EditDialogContextObj?.setIsRefreshing(true);
		await refetch();
		EditDialogContextObj?.setIsRefreshing(false);
	}, [EditDialogContextObj, refetch]);

	return (
		<Button
			text
			onClick={onClick}
			title={title}
			style={{ padding: 0, minWidth: 0 }}
		>
			<SyncIcon size="medium" style={{ minWidth: '1rem' }} />
			<Text style={{ marginLeft: '.5rem' }}>{title}</Text>
		</Button>
	);
}
