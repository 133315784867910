import { useEffect, useState } from 'react';

import * as Teams from '@microsoft/teams-js';

type TabSettings = {
	server: string;
	fileId: string;
	tabId: string;
};

export function useTabSettings() {
	const [config, setConfig] = useState<TabSettings>({
		server: '',
		fileId: '',
		tabId: ''
	});

	useEffect(() => {
		let isStillMounted = true;

		Teams.initialize(() => {
			Teams.settings.getSettings(settings => {
				if (isStillMounted && settings.contentUrl) {
					const contentUrl = new URL(settings.contentUrl),
						query = new URLSearchParams(contentUrl.search),
						storedServer = query.get('server'),
						storedTabId = query.get('tabId'),
						storedFileId = query.get('fileId');

					if (storedServer && storedFileId && storedTabId) {
						setConfig({
							server: storedServer,
							fileId: storedFileId,
							tabId: storedTabId
						});
					}
				}
			});
		});

		return () => {
			isStillMounted = false;
		};
	}, []);

	return config;
}
