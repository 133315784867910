import { Box } from '@fluentui/react-northstar';
import { getSchemeByExtension } from 'components/Document/Scheme';
import { ClickHandler } from 'components/Table/Actions';
import { TableRowData } from 'types';

export function OpenByUri({
	row,
	label,
	clickHandler
}: {
	row: TableRowData;
	label: string;
	clickHandler?: ClickHandler;
}) {
	if (clickHandler) {
		clickHandler.clickAction = () => openByUriScheme(row);
	}
	return <Box>{label}</Box>;
}

export function openByUriScheme(row: TableRowData) {
	const link = getDocumentLink(row);

	tryOpenByUriScheme(link);
}

export function getDocumentLink(row: TableRowData) {
	return [
		row.data.Document['odata.mediaReadLink'],
		row.data.Document.Extension,
		row.data.Locked
	];
}

export function tryOpenByUriScheme([link, extension, locked]: string[]) {
	const scheme = getSchemeByExtension(extension);

	if (scheme) {
		const method = locked === 'L' ? 'ofv' : 'ofe'; // View | Edit document

		download(`${scheme}:${method}|u|${link}`);
	}
}

export function download(scheme: string) {
	const opener = document.getElementById('wzc-opener');

	if (opener) {
		opener.setAttribute('src', scheme);
	}
}
