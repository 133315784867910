type ODataModifiers = {
	encoders: { [index: string]: (...arg: any) => any };
	process: (...arg: any) => any;
};

const Modifiers: ODataModifiers = {
	// Odata request parts which we need to encode special characters
	encoders: {
		select(value = '') {
			// custom properties could have localized values
			return encodeURI(value);
		},
		orderby(value = '') {
			// custom properties could have localized values
			return encodeURI(value);
		},
		dateRelativeness(value = '') {
			// relative values could have '+'
			return value
				.split(',')
				.map((p: string) => encodeURIComponent(p))
				.join(',');
		},
		filter(value = '') {
			// user entries should be encoded anyway
			return value.replace(/[^\s()':/]+/g, encodeURIComponent);
		},
		// generic one, as collection names created by users could have localized chars
		ensure(value = '') {
			const decoded = decodeURI(value); // decode initial value first, as it might be ALREADY encoded, second encoding will mess things up

			if (value === decoded) {
				// encode value if it does not already encoded
				return encodeURI(decoded);
			} else {
				return value;
			}
		}
	},

	process(collection = '', keys: string[] = [], values: any = {}) {
		// sort CustomDomains by Rank
		if (collection.includes('CustomDomains') && !keys.includes('orderby')) {
			keys.push('orderby');

			values.orderby = 'Rank';
		}
	}
};

export default Modifiers;
