import { Box, Flex } from '@fluentui/react-northstar';
import { useCallback, useMemo } from 'react';
import * as Teams from '@microsoft/teams-js';
import { RemoveConfigFromStorage } from './Table/LocalStorageHandler';

export default function TabRemove() {
	const removeTabColumnSettings = useCallback(
		(removeEvent: Teams.settings.RemoveEvent) => {
			Teams.settings.getSettings(settings => {
				const contentUrl = new URL(settings.contentUrl),
					query = new URLSearchParams(contentUrl.search),
					tabId = query.get('tabId');
				if (tabId) {
					RemoveConfigFromStorage(tabId);
					removeEvent.notifySuccess();
				}
			});
		},
		[]
	);

	useMemo(() => {
		Teams.initialize(() =>
			Teams.settings.registerOnRemoveHandler(removeTabColumnSettings)
		);
		Teams.settings.setValidityState(true);
	}, [removeTabColumnSettings]);

	return (
		<Flex column>
			<h4>Want to remove this tab?</h4>
			<Box>The tab will be permanently removed.</Box>
		</Flex>
	);
}
