import Form from 'components/Form';
import Notification from 'components/Notification';
import {
	useActionableContext,
	useEditDialogContext
} from 'components/Table/ActionableTable';
import { TableRowData } from 'types';
import { useOdataMutationInQuery } from 'components/WorkZone/Odata/Odata';
import { useCallback } from 'react';

export function Title({ row }: { row: TableRowData }) {
	const { setDialogOpen } = useEditDialogContext();
	const { source } = useActionableContext();

	const recordMutation = useOdataMutationInQuery({
		collection: 'Records',
		id: row.id,
		key: source?.key,
		select: source?.source.getProperties()
	});

	const saveAction = useCallback(
		async (title: string) => {
			try {
				const result = await recordMutation.mutateAsync({
					ID: row.id,
					Title: title,
					'odata.type': 'Som.Record'
				} as any);

				if (result['odata.error'] !== undefined) {
					Notification.error(result['odata.error'].message.value);
					return false;
				}

				return true;
			} catch (e: any) {
				Notification.error(e.message);
				return false;
			}
		},
		[recordMutation, row.id]
	);

	const onSubmit = useCallback(
		async ({ getValues }) => {
			const { Title: title } = getValues();

			if (title) {
				return saveAction(title);
			}

			return false;
		},
		[saveAction]
	);

	const closeDialog = useCallback(
		() => setDialogOpen(false),
		[setDialogOpen]
	);

	return (
		<Form
			name="Records.Title"
			source={row.data}
			onSubmit={onSubmit}
			onAfterSubmit={closeDialog}
			onCancel={closeDialog}
		/>
	);
}
