import {
	Box,
	Button,
	Text,
	Checkbox,
	Flex,
	Loader,
	SettingsIcon
} from '@fluentui/react-northstar';
import { useEditDialogContext } from 'components/Table/ActionableTable';
import {
	GetConfigFromStorage,
	SaveConfigToStorage
} from 'components/Table/LocalStorageHandler';
import type { InfiniteSource } from 'hook';
import OData, {
	ODataAccept,
	ODataTake,
	useRequestOptions
} from 'components/WorkZone/Odata/Odata';
import { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import * as Teams from '@microsoft/teams-js';
import GetDefaultLayout, {
	ColumnLayout,
	Customizable
} from 'components/Table/utility/getDefaultLayout';
import { ColumnSettingsContext } from 'components/Tab';
import {
	cloneColumnItem,
	ColumnItem,
	ColumnSettingsDialog,
	layoutColumnsToColumnItems
} from 'components/Table/ColumnSettingsDialog';

export function ColumnSettingsAction({
	source,
	title
}: {
	source: InfiniteSource;
	title: string;
}) {
	const { setDialogName, setDialogOpen, setDialogContent } =
		useEditDialogContext();
	const [tabId, setTabId] = useState('');
	const setColumnSettings = useContext(ColumnSettingsContext);

	const [currentColumnItems, setCurrentColumnItems] = useState<ColumnItem[]>(
		[]
	);
	const [availableColumnItems, setAvailableColumnItems] = useState<
		ColumnItem[]
	>([]);
	const [availableNewColumnItems, setAvailableNewColumnItems] = useState<
		ColumnItem[]
	>([]);
	const [defaultLayoutColumnItems, setDefaultLayoutColumnItems] = useState<
		ColumnItem[]
	>([]);

	const [loading, setLoading] = useState(false);

	const getSettings = useCallback(settings => {
		const contentUrl = new URL(settings.contentUrl),
			query = new URLSearchParams(contentUrl.search);
		setTabId(query.get('tabId') ?? '');
	}, []);

	useMemo(() => {
		Teams.initialize(() => {
			Teams.settings.getSettings(getSettings);
		});
	}, [getSettings]);

	const saveColumnSettings = useCallback(() => {
		if (currentColumnItems && !loading) {
			const mappedColumns = currentColumnItems.map<
				[string, ColumnLayout]
			>(item => {
				item.value.order = currentColumnItems.indexOf(item) + 1;
				return [item.id, item.value];
			});
			const newColumns = Object.fromEntries(mappedColumns);
			const newLayout = {
				[source.source.collection]: {
					columns: newColumns
				}
			};
			SaveConfigToStorage(tabId, newLayout);
			setColumnSettings(GetConfigFromStorage(tabId));
			setDialogOpen(false);
		}
	}, [
		currentColumnItems,
		loading,
		setColumnSettings,
		setDialogOpen,
		source.source.collection,
		tabId
	]);

	const metadataRequestOptions = useRequestOptions({
		accept: ODataAccept.XML,
		take: ODataTake.Raw,
		meta: 'full',
		enabled: true
	});

	const getAvailableColumns = useCallback(async () => {
		const metadata = await OData.request(
			'V3/$metadata',
			metadataRequestOptions
		);
		const metadataSchema =
			metadata['edmx:Edmx']['edmx:DataServices'].Schema[0].EntityType;
		const recordSchema = metadataSchema.filter(
			(x: { _attributes: { Name: string } }) =>
				x._attributes.Name === 'Record'
		);
		return convertMetadataIntoLayoutColumns(recordSchema[0].Property);
	}, [metadataRequestOptions]);

	function convertMetadataIntoLayoutColumns(
		customPropsMetadata: any
	): ColumnItem[] {
		return customPropsMetadata.map((entity: any) => {
			const name = entity._attributes.Name.toString();

			const item: ColumnItem = {
				value: {
					width: 'fit-content',
					label: entity._attributes['ds:DisplayName'],
					order: customPropsMetadata.indexOf(entity) + 1,
					customizable: Customizable.full
				},
				header: entity._attributes['ds:DisplayName'] ?? name,
				media: (
					<Checkbox
						styles={{ marginRight: '-10px' }}
						onChange={(...props) => {
							item.isSelected = props?.[1]?.checked ?? false;
						}}
					/>
				),
				isSelected: false,
				content: name,
				id: name
			};

			return item;
		});
	}

	const setAvailableColumns = useCallback((columns: ColumnItem[]) => {
		columns.sort((a: ColumnItem, b: ColumnItem) =>
			a.header.localeCompare(b.header)
		);

		setAvailableNewColumnItems([...columns]);
	}, []);

	const initialize = useCallback(async () => {
		setDialogName(title);
		setLoading(true);
		setDialogOpen(true);
		if (!tabId) {
			return;
		}
		const availableColumns = await getAvailableColumns();
		setAvailableColumnItems(availableColumns);

		const currentLayout = GetConfigFromStorage(tabId) ?? GetDefaultLayout();
		const currentColumns = currentLayout[source.source.collection].columns;
		const allCurrentColumnItems =
			layoutColumnsToColumnItems(currentColumns);
		const customizableCurrentItems = allCurrentColumnItems.filter(
			entry => entry.value.customizable !== Customizable.readonly
		);
		setCurrentColumnItems(customizableCurrentItems);

		const newAvailableColumns = availableColumns
			.filter(
				(column: ColumnItem) =>
					!allCurrentColumnItems.some(x => x.id === column.id)
			)
			.map(item => cloneColumnItem(item));
		setAvailableColumns(newAvailableColumns);

		const layout = GetDefaultLayout();
		const defaultLayoutColumns = layout[source.source.collection].columns;
		setDefaultLayoutColumnItems(
			layoutColumnsToColumnItems(defaultLayoutColumns)
		);

		setLoading(false);
	}, [
		getAvailableColumns,
		setAvailableColumns,
		setDialogName,
		setDialogOpen,
		source.source.collection,
		tabId,
		title
	]);

	const close = useCallback(() => {
		setLoading(false);
		setDialogOpen(false);
	}, [setDialogOpen]);

	useEffect(() => {
		if (loading) {
			setDialogContent(
				<Box>
					<Loader />
					<Flex hAlign={'end'}>
						<Button secondary onClick={close}>
							Cancel
						</Button>
					</Flex>
				</Box>
			);
		} else {
			setDialogContent(
				<ColumnSettingsDialog
					availableColumnItems={availableColumnItems}
					availableNewColumnItems={availableNewColumnItems}
					setAvailableNewColumnItems={setAvailableColumns}
					currentColumnItems={currentColumnItems}
					setCurrentColumnItems={setCurrentColumnItems}
					onSaveClicked={saveColumnSettings}
					onCancelClicked={close}
					defaultColumnItems={defaultLayoutColumnItems}
				/>
			);
		}
	}, [
		availableColumnItems,
		availableNewColumnItems,
		close,
		currentColumnItems,
		defaultLayoutColumnItems,
		loading,
		saveColumnSettings,
		setAvailableColumns,
		setDialogContent,
		setDialogOpen,
		source.source.collection
	]);

	return (
		<Button
			text
			onClick={initialize}
			title={title}
			style={{ padding: 0, minWidth: 0 }}
		>
			<SettingsIcon size="medium" style={{ minWidth: '1rem' }} />
			<Text style={{ marginLeft: '.5rem' }}>{title}</Text>
		</Button>
	);
}
