import React, { useCallback, useMemo } from 'react';

import { Flex, FormButton } from '@fluentui/react-northstar';
import { useFormContext } from 'react-hook-form';

import { capitalizeFirstLetter } from 'utils';
import { FormOptions } from 'components/Form';
import { FormButtonLayout, FormLayout } from 'components/Form/Layout';
import { ControlType } from '../controls';

export default function FormButtons({
	actionInProgress,
	submitAllowed,
	layout,
	...rest
}: FormOptions & { layout: FormLayout; actionInProgress: boolean }) {
	const { buttons } = layout;
	const isFormWithoutInputs =
		Object.keys(layout.controls).length === 0 ||
		Object.values(layout.controls)?.every(x => x.type === ControlType.Text);

	if (buttons) {
		const instances = buttons.map((button, i) => (
			<Button
				key={i}
				actionInProgress={actionInProgress}
				submitAllowed={submitAllowed}
				{...rest}
				isFormWithoutInputs={isFormWithoutInputs}
				{...button}
			/>
		));

		if (instances.length) {
			return (
				<Flex gap="gap.smaller" hAlign="end">
					{instances}
				</Flex>
			);
		}
	}

	return null;
}

function Button({
	role,
	label,
	enabled,
	align,
	actionInProgress,
	isFormWithoutInputs,
	submitAllowed = true,
	...rest
}: React.PropsWithChildren<FormButtonLayout>) {
	const canSubmit = useCanSubmit() && submitAllowed;

	const cancelActionHandler = useCancelAction(rest);

	const customActionHandler = useCustomAction(role, rest);

	const props: any = {
		content: label || role
	};

	if (['submit', 'cancel'].includes(role)) {
		if (role === 'submit') {
			setSubmitButtonProps(
				props,
				isFormWithoutInputs ? true : canSubmit,
				actionInProgress
			);
		}

		if (role === 'cancel') {
			setCancelButtonProps(props, actionInProgress, cancelActionHandler);
		}
	} else {
		props.primary = true;

		props.onClick = (e: any) => {
			customActionHandler(e);
		};

		if (!enabled) {
			props.disabled = !canSubmit;
		}
	}

	if (align === 'left') {
		props.styles = {
			flexGrow: 1
		};
	}

	return <FormButton {...props} />;
}

function setSubmitButtonProps(
	props: any,
	canSubmit: boolean,
	actionInProgress: boolean | undefined
) {
	props.primary = true;

	props.disabled = !canSubmit;

	if (actionInProgress) {
		props.loading = true;
	}
}

function setCancelButtonProps(
	props: any,
	actionInProgress: boolean | undefined,
	cancelActionHandler: any
) {
	props.secondary = true;

	if (actionInProgress) {
		props.disabled = true;
	}

	props.onClick = cancelActionHandler;
}

function useCanSubmit() {
	const formContext = useFormContext();

	const { formState } = formContext;

	const { isValid, isDirty } = formState;

	return useMemo(() => isValid && isDirty, [isDirty, isValid]);
}

function useCancelAction(formProps: any) {
	const formContext = useFormContext();

	const { onCancel = () => void 0 } = formProps;

	return useCallback(
		async (e, ...originalArgs) => {
			e.preventDefault();

			return onCancel(formContext, e, ...originalArgs);
		},
		[formContext, onCancel]
	);
}

function useCustomAction(role: string, formProps: any) {
	const formContext = useFormContext();

	const handlerName = `on${capitalizeFirstLetter(role)}`;

	const { [handlerName]: onAction = () => void 0 } = formProps;

	return useCallback(
		async (e, ...originalArgs) => {
			e.preventDefault();

			return onAction(formContext, e, ...originalArgs);
		},
		[onAction, formContext]
	);
}
