import TableSource, { TableSourceOptions } from 'components/Table/TableSource';
import { ListLayout } from 'components/Table/utility/getDefaultLayout';
import Constants from 'components/WorkZone/Odata/Constants';
import {
	useInifiniteOdata,
	useOdata,
	ODataAccept
} from 'components/WorkZone/Odata/Odata';
import { useState, useMemo, useEffect } from 'react';
import { UseInfiniteQueryResult } from 'react-query';

export type InfiniteSource = UseInfiniteQueryResult<any, unknown> & {
	source: TableSource;
	count: number;
	key: string;
};

export function useInfiniteSource(
	options: TableSourceOptions,
	customLayout: { [collection: string]: ListLayout } | undefined
): InfiniteSource {
	const [shouldCount, startCounting] = useState(false);

	const [useCount, setUseCount] = useState<number | null>(null);

	const source = useMemo(
		() => new TableSource(options, customLayout),
		[customLayout, options]
	);

	const properties = source.getProperties();

	const query = useInifiniteOdata({
		[source.options.collection]: {
			select: properties,
			filter: source.options.filter,
			orderby: source.options.orderby || 'ID'
		}
	});

	const { data, refetch, isLoading, isFetching } = query;

	useEffect(() => {
		if (!isLoading && !isFetching) {
			if (data) {
				if (data.pages && data.pages[0][Constants.keys.nextLink]) {
					startCounting(true);
				} else {
					setUseCount(data.pages[0]?.value?.length || 0);
				}
			} else {
				setUseCount(0);
			}
		}
	}, [data, isFetching, isLoading]);

	const count = useCountingSource(
		options,
		customLayout,
		shouldCount,
		useCount
	);

	const refetchWrapper = async () => {
		startCounting(false);

		return refetch({
			cancelRefetch: true
		});
	};

	return { ...query, source, count, refetch: refetchWrapper };
}

function useCountingSource(
	options: any,
	customLayout?: { [collection: string]: ListLayout },
	shouldCount = false,
	useCount: number | null = null
) {
	const [count, setCount] = useState(0);

	const source = useMemo(
		() => new TableSource(options, customLayout),
		[customLayout, options]
	);

	const { data, isLoading, isFetching } = useOdata(
		{
			[`${source.options.collection}/$count`]: {
				filter: source.options.filter
			}
		},
		{
			accept: ODataAccept.Text,
			enabled: shouldCount
		}
	);

	useEffect(() => {
		if (!isLoading && !isFetching && data) {
			setCount(Number(data) || 0);
		}
	}, [data, isFetching, isLoading]);

	useEffect(() => {
		if (useCount !== null) {
			setCount(Number(useCount));
		}
	}, [useCount]);

	return count;
}
