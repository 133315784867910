import { useMemo } from 'react';

import {
	ComponentEventHandler,
	MenuItemProps,
	Menu,
	menuAsToolbarBehavior
} from '@fluentui/react-northstar';

import {
	AddIcon,
	WordIcon,
	PowerPointIcon,
	ExcelIcon,
	FilesUploadIcon
} from '@fluentui/react-icons-northstar';

import { useTabSettings } from 'components/App/TabSettings';
import { useCreateDocument } from 'components/Document/Create';
import { useUploader } from 'components/Uploader/Uploader';
import { RefreshListAction } from 'components/Table/CollectionActions/Record/RefreshListAction';
import { ColumnSettingsAction } from 'components/Table/CollectionActions/Record/ColumnSettingsAction';
import type { InfiniteSource } from 'hook';

const templates = ['dotx', 'xltx', 'potx'];

export default function Toolbar({ source }: { source?: InfiniteSource }) {
	const { fileId } = useTabSettings();
	const { create } = useCreateDocument(fileId);
	const { open } = useUploader();

	const onItemClick: ComponentEventHandler<MenuItemProps> = function (
		e,
		item
	) {
		if (item && typeof item.index !== 'undefined') {
			create(templates[item.index]);
		}
	};

	const itemStyles = useMemo(
		() => ({
			paddingRight: '1.5rem'
		}),
		[]
	);

	const items = [
		{
			icon: <AddIcon outline />,
			key: 'addButton',
			content: 'New',
			'aria-label': 'Create new',
			//indicator: false,
			style: itemStyles,
			menu: {
				items: [
					{
						key: 'addNewWord',
						content: 'Word document',
						icon: <WordIcon />,
						onClick: onItemClick
					},
					{
						key: 'addNewExcel',
						content: 'Excel workbook',
						icon: <ExcelIcon />,
						onClick: onItemClick
					},
					{
						key: 'addNewPowerPoint',
						content: 'PowerPoint presentation',
						icon: <PowerPointIcon />,
						onClick: onItemClick
					}
				]
			}
		},
		{
			icon: <FilesUploadIcon outline />,
			key: 'uploadButton',
			content: 'Upload',
			'aria-label': 'Upload',
			onClick: open
		},
		{
			content: source && (
				<RefreshListAction source={source} title="Refresh" />
			),
			key: 'refreshButton',
			'aria-label': 'Refresh'
		},
		{
			content: source && (
				<ColumnSettingsAction source={source} title="Settings" />
			),
			key: 'settingButton',
			'aria-label': 'Settings'
		}
	];

	const menuStyles = useMemo(
		() => ({
			padding: '5px',
			marginBottom: '20px',
			paddingLeft: '20px',
			boxShadow:
				'rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px'
		}),
		[]
	);

	return (
		<Menu
			items={items}
			iconOnly
			accessibility={menuAsToolbarBehavior}
			aria-label="Tab actions"
			style={menuStyles}
		/>
	);
}
