import { useEffect, useState } from 'react';

import * as Teams from '@microsoft/teams-js';
import {
	Provider,
	mergeThemes,
	teamsTheme,
	teamsDarkTheme,
	teamsHighContrastTheme
} from '@fluentui/react-northstar';

/**
 * Theme toggler provider
 */
export default function Theme({ children }: React.PropsWithChildren<unknown>) {
	const themes: { [index: string]: any } = {
		default: teamsTheme,
		dark: teamsDarkTheme,
		contrast: teamsHighContrastTheme
	};

	const [theme, setTheme] = useState('default');

	useEffect(() => {
		Teams.initialize(() => {
			Teams.getContext(context => {
				if (context.theme) {
					setTheme(context.theme);
				}
			});

			Teams.registerOnThemeChangeHandler(newTheme => {
				setTheme(newTheme);
			});

			Teams.appInitialization.notifySuccess();
		});
	}, []);

	const customizedTheme = mergeThemes(themes[theme], {
		siteVariables: {
			bodyBackground: 'transparent'
		}
	});

	return <Provider theme={customizedTheme}>{children}</Provider>;
}
