import { useCallback, useEffect, useState } from 'react';

import * as Teams from '@microsoft/teams-js';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import {
	Form,
	FormInput,
	FormDropdown,
	ChevronDownIcon,
	Loader
} from '@fluentui/react-northstar';

import {
	useForm,
	useWatch,
	useFormContext,
	FormProvider,
	Controller,
	ControllerRenderProps,
	ControllerFieldState,
	FieldValues
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useDebouncedCallback, useDebounce } from 'use-debounce';
import { detrail, joinPath, uuid } from 'utils';

import { WorkZone } from 'components/WorkZone';
import { useTabSettings } from 'components/App/TabSettings';
import { useWorkZone } from 'components/WorkZone/WorkZoneProvider';
import { useOdata } from 'components/WorkZone/Odata/Odata';

const schema = z.object({
	server: z
		.string()
		.nonempty('Provide a WorkZone server you want to connect to')
		.url('Should be a valid WorkZone address'),
	fileId: z
		.string()
		.nonempty('Please select a case you want to list documents from')
});

function ServerInput() {
	const { control } = useFormContext();

	return (
		<Controller
			render={({ ...rest }) => <ServerSelectorInput {...rest} />}
			control={control}
			name="server"
		/>
	);
}

function ServerSelectorInput({
	field,
	fieldState
}: {
	field: ControllerRenderProps | ControllerRenderProps<FieldValues, 'server'>;
	fieldState: ControllerFieldState;
}) {
	return (
		<FormInput
			id="server"
			label="WorkZone server address"
			placeholder="https://"
			autoComplete="off"
			fluid
			//clearable
			errorMessage={fieldState.error?.message}
			{...field}
		/>
	);
}

function CaseInput() {
	const { control } = useFormContext();

	const server = useWatch({
		control,
		name: 'server',
		defaultValue: ''
	});

	const [debounced, debounce] = useDebounce(server, 300);

	const endpoint = debounce.isPending() ? '' : debounced;

	return (
		<WorkZone endpoint={endpoint}>
			<Controller
				render={({ ...rest }) => (
					<CaseSelectorDropdownAuthorized {...rest} />
				)}
				control={control}
				name="fileId"
			/>
		</WorkZone>
	);
}

type TCaseSelectorDropdown = {
	field: ControllerRenderProps;
	fieldState: ControllerFieldState;
};

function CaseSelectorDropdownAuthorized({ ...rest }) {
	const { canAuthorize } = useWorkZone();

	if (canAuthorize) {
		return <CaseSelectorDropdown {...(rest as TCaseSelectorDropdown)} />;
	} else {
		return <CaseSelectorDisabled />;
	}
}

function CaseSelectorDisabled() {
	return (
		<FormDropdown
			id="fileId"
			label="WorkZone case"
			items={[]}
			disabled
			search
			fluid
			autoSize
		/>
	);
}

function CaseSelectorDropdown({ field, fieldState }: TCaseSelectorDropdown) {
	const { canAuthorize, endpoint } = useWorkZone();
	const { fileId } = useTabSettings();
	const { formState } = useFormContext();

	const canSelect =
		canAuthorize &&
		endpoint &&
		typeof formState.errors?.server === 'undefined';

	const [cases, setCases] = useState<any>([]);
	const [criteria, setCriteria] = useState('');
	const [placeholder, setPlaceholder] = useState('');
	const [stateIcon, setStateIcon] = useState(<ChevronDownIcon />);
	const [clearable, setClearable] = useState(field.value !== '');
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const debounced = useDebouncedCallback((e: any, { searchQuery }: any) => {
		setCriteria(searchQuery);
	}, 1000);

	const filter = ['Mru ne null'];

	if (criteria) {
		filter.push(`Summary eq '${criteria}*'`);
	}

	const { data: casesFromEndpoint, isLoading: casesLoading } = useOdata(
		{
			Files: {
				select: ['ID', 'Summary'],
				filter: filter.join(' and '),
				orderby: 'Mru/User_Value,Mru/Updated desc,Summary'
			}
		},
		{
			enabled: Boolean(canSelect)
		}
	);

	const { data: caseByFileId, isLoading: caseByFileIdLoading } = useOdata(
		{
			[`Files('${fileId}')`]: {
				select: ['Summary']
			}
		},
		{
			enabled: Boolean(canSelect && fileId)
		}
	);

	useEffect(() => {
		setPlaceholder('');

		if (canSelect && fileId) {
			batchedUpdates(() => {
				setClearable(false);

				setStateIcon(<Loader size="small" />);
			});

			if (!caseByFileIdLoading && caseByFileId) {
				batchedUpdates(() => {
					setPlaceholder(caseByFileId.Summary);

					setStateIcon(<ChevronDownIcon />);

					setClearable(field.value !== '');
				});
			}
		}
	}, [fileId, field, canSelect, caseByFileIdLoading, caseByFileId]);

	useEffect(() => {
		if (!casesLoading && casesFromEndpoint) {
			setCases(
				casesFromEndpoint.value.map((item: any) => ({
					header: item?.Summary,
					id: item?.ID,
					key: `file-${item?.ID}`
				}))
			);
			if (casesFromEndpoint.value.length === 0) {
				setDropdownOpen(false);
			}
		}
	}, [casesFromEndpoint, casesLoading]);

	const openChangeHandler = () => {
		if (
			!dropdownOpen &&
			(debounced.isPending() || casesLoading || cases.length > 0)
		) {
			setDropdownOpen(true);
		} else {
			setDropdownOpen(false);
		}
	};

	return (
		<FormDropdown
			id="fileId"
			label="WorkZone case"
			items={cases}
			disabled={!canSelect}
			onSearchQueryChange={debounced}
			clearable={clearable}
			loading={debounced.isPending() || casesLoading}
			loadingMessage="Loading..."
			errorMessage={canSelect && fieldState.error?.message}
			search
			fluid
			open={dropdownOpen}
			onOpenChange={openChangeHandler}
			autoSize
			{...field}
			ref={null}
			toggleIndicator={stateIcon}
			placeholder={placeholder} // dirty hack until https://github.com/microsoft/fluentui/issues/17413 is fixed
			onChange={(e, { value }) => {
				setPlaceholder(''); // clear hacky text

				let actualValue = '';

				if (value) {
					// typescript baby
					const actual = value as { id: string; header: string };

					actualValue = actual.id;
					setDropdownOpen(false);
				} else {
					setCriteria('');
				}

				return field.onChange(actualValue);
			}}
		/>
	);
}

function FormContainer() {
	const { reset, getValues } = useFormContext();

	const { server, fileId } = useTabSettings();

	useEffect(() => {
		if (server) {
			reset({
				...getValues(),
				server /* ,
				fileId */
			});
		}
	}, [server, fileId, reset, getValues]);

	return (
		<Form>
			<ServerInput />
			<CaseInput />
		</Form>
	);
}

/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */
export default function TabConfig() {
	const [teamsTabId, synchronizeTeamsTabId] = useState('');

	const formContext = useForm({
		mode: 'onChange',
		resolver: zodResolver(schema, { async: true }, { mode: 'async' }),
		defaultValues: {
			server: '',
			fileId: ''
		}
	});

	const { formState, getValues } = formContext;

	const onSaveHandler = useCallback(() => {
		const { server, fileId } = getValues();

		const baseUrl = joinPath(
			`https://${window.location.hostname}:${window.location.port}`,
			process.env.PUBLIC_URL
		);

		const settings = `?server=${encodeURIComponent(
			detrail(server)
		)}&fileId=${fileId}&tabId=${teamsTabId ? teamsTabId : uuid()}`;

		Teams.settings.setSettings({
			suggestedDisplayName: 'WorkZone',
			entityId: 'Test',
			contentUrl: `${baseUrl}/tab${settings}`,
			websiteUrl: `${baseUrl}/tab`,
			removeUrl: `${baseUrl}/remove`
		});
	}, [getValues, teamsTabId]);

	const synchronizeSettings = useCallback(settings => {
		if (!settings.contentUrl) {
			return;
		}
		const contentUrl = new URL(settings.contentUrl),
			query = new URLSearchParams(contentUrl.search),
			tabId = query.get('tabId');
		synchronizeTeamsTabId(tabId ?? '');
	}, []);

	useEffect(() => {
		Teams.initialize(() => {
			Teams.settings.getSettings(synchronizeSettings);

			/**
			 * When the user clicks "Save", save the url for your configured tab.
			 * This allows for the addition of query string parameters based on
			 * the settings selected by the user.
			 */
			Teams.settings.registerOnSaveHandler(saveEvent => {
				onSaveHandler();

				saveEvent.notifySuccess();
			});
		});
	}, [onSaveHandler, synchronizeSettings]);

	useEffect(() => {
		Teams.initialize(() => {
			const { isValid, isDirty } = formState;

			/**
			 * After verifying that the settings for your tab are correctly
			 * filled in by the user you need to set the state of the dialog
			 * to be valid.  This will enable the save button in the configuration
			 * dialog.
			 */
			Teams.settings.setValidityState(isValid && isDirty);
		});
	}, [formState]);

	return (
		<FormProvider {...formContext}>
			<FormContainer />
		</FormProvider>
	);
}
