import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';

import Tab from 'components/Tab';
import TabConfig from 'components/TabConfig';

import Authenticate from 'components/App/Authenticate';

import ErrorFallback from 'components/App/Error';
import Theme from 'components/App/Theme';
import TabRemove from 'components/TabRemove';

const queryClient = new QueryClient();

export default function App() {
	// Display the app home page hosted in Teams
	return (
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
			}}
		>
			<QueryClientProvider client={queryClient}>
				<Theme>
					<BrowserRouter basename={process.env.PUBLIC_URL}>
						<Routes>
							<Route path="/" element={<Navigate to="/tab" />} />
							<Route path="/tab" element={<Tab />} />
							<Route path="/config" element={<TabConfig />} />
							<Route path="/auth" element={<Authenticate />} />
							<Route path="/remove" element={<TabRemove />} />
						</Routes>
					</BrowserRouter>
				</Theme>
			</QueryClientProvider>
		</ErrorBoundary>
	);
}
