export default function readProperty(propertyName = '', source = {}) {
	const read: unique symbol = Symbol.for('read'),
		propertyParts = propertyName.split('/');

	return propertyParts.reduce(
		(root, prop) => readPropertyReducer(propertyParts, read, root, prop),
		source
	);
}

function readPropertyReducer(
	propertyParts: string[],
	read: symbol,
	root: any,
	prop: string
) {
	if (root) {
		if (Array.isArray(root)) {
			return reduceArrayProperty(propertyParts, read, root, prop);
		} else {
			if (typeof prop !== 'undefined') {
				if (typeof root[prop] !== 'undefined') {
					return root[prop];
				} else {
					return void 0;
				}
			} else {
				return root;
			}
		}
	}

	return void 0;
}

function reduceArrayProperty(
	propertyParts: string[],
	read: symbol,
	root: any,
	prop: string
) {
	if (root[read]) {
		delete root[read];

		return root;
	}

	let matched = false;

	const partialPropertyName = propertyParts
		.reduce((acc, p) => {
			if (p === prop || matched) {
				matched = true;

				acc.push(p);
			}

			return acc;
		}, [] as string[])
		.join('/');

	const mapped = root.map((p: any) => readProperty(partialPropertyName, p));
	// prevent recursion
	mapped[read] = true;

	return mapped;
}
