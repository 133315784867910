import { Flex, Box } from '@fluentui/react-northstar';
import React from 'react';

export default function Content({
	children,
	center,
	style
}: React.PropsWithChildren<{ center?: boolean; style?: React.CSSProperties }>) {
	if (center) {
		return <CenteredContent style={style}>{children}</CenteredContent>;
	} else {
		return <Box style={style}>{children}</Box>;
	}
}

function CenteredContent({
	children,
	style = {}
}: React.PropsWithChildren<{ style?: React.CSSProperties }>) {
	const finalStyle = {
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		...style
	};

	return <Flex style={finalStyle}>{children}</Flex>;
}
