/**
 * Builds a URL from args provided, joining them by /
 */
export default function joinPath(...args: string[]) {
	return args
		.reduce((acc: string[], path) => {
			if (path) {
				path = path.replace(/^\/+|\/+$/, ''); // trim redundant slashes from both sides
			}

			acc.push(path);

			return acc;
		}, [])
		.filter(Boolean)
		.join('/'); // ensure we don't have blanks in url
}
