import React, {
	createContext,
	Suspense,
	useCallback,
	useState,
	useContext,
	useMemo,
	useRef
} from 'react';
import { Loader, Form as FluentForm } from '@fluentui/react-northstar';

import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import FormButtons from 'components/Form/buttons';

import { FormLayout, getLayoutByName } from 'components/Form/Layout';

import { buildSchemaFromLayout } from 'components/Form/Schema';
import { buildControlsFromLayout } from 'components/Form/controls';
import { extend } from 'utils';

const FormContext = createContext({});

export type FormSource = { [key: string]: any };

export type FormOptions = {
	name: string;
	source?: FormSource;
	layout?: FormLayout;
	onSubmit?: (e: any) => Promise<any>;
	onAfterSubmit?: () => void;
	onCancel?: () => void;
	submitAllowed?: boolean;
};

function buildFormDefaultValuesFromLayout(
	layout: FormLayout,
	source: FormSource = {}
) {
	const defaultValues: any = {};

	const { controls } = layout;

	if (controls) {
		Object.keys(controls).forEach(name => {
			defaultValues[name] = source[name] || '';
		});
	}

	return defaultValues;
}

function getFormOptionsFromLayout(layout: FormLayout, source: FormSource = {}) {
	const schema = buildSchemaFromLayout(layout);

	const options: any = {
		mode: 'onChange',
		defaultValues: buildFormDefaultValuesFromLayout(layout, source)
	};

	if (schema) {
		options.resolver = zodResolver(
			schema,
			{ async: true },
			{ mode: 'async' }
		);
	}

	return options;
}

export default function Form({
	name,
	source,
	layout,
	submitAllowed,
	...rest
}: FormOptions & { [key: string]: any }) {
	layout = layout || getLayoutByName(name);

	const controls = buildControlsFromLayout(layout, source);

	const formContext = useForm(getFormOptionsFromLayout(layout, source));

	return (
		<Suspense fallback={<Loader />}>
			<FormProvider {...formContext}>
				<FormWrapper
					name={name}
					layout={layout}
					submitAllowed={submitAllowed}
					{...rest}
				>
					{controls}
				</FormWrapper>
			</FormProvider>
		</Suspense>
	);
}

function FormWrapper({
	children,
	submitAllowed,
	...rest
}: React.PropsWithChildren<FormOptions & { layout: FormLayout }>) {
	const context = useFormContext();
	const [actionInProgress, setActionInProgress] = useState<boolean>(false);

	const {
		onSubmit = async () => void 0,
		onAfterSubmit = async () => void 0
	} = rest as any;

	const wrappedSubmit = useCallback(
		async (...originalArgs: any) => {
			setActionInProgress(true);

			const submitResult = await onSubmit(context, ...originalArgs);

			setActionInProgress(false);

			if (submitResult !== false) {
				await onAfterSubmit(context, ...originalArgs);
			}
		},
		[context, onAfterSubmit, onSubmit]
	);

	const workZoneValues = useRef({});

	const ownContext = useMemo(
		() => ({
			// TODO: the naming below should be reviewed
			workZoneValues: workZoneValues.current,
			getWorkZoneValues: function () {
				return {
					...context.getValues(),
					...workZoneValues.current
				};
			},
			setWorkZoneValues: function (values: any) {
				extend(workZoneValues.current, values);
			}
		}),
		[context]
	);

	return (
		<FluentForm onSubmit={wrappedSubmit}>
			<FormContext.Provider value={ownContext}>
				{children}
				<FormButtons
					{...rest}
					actionInProgress={actionInProgress}
					submitAllowed={submitAllowed}
				/>
			</FormContext.Provider>
		</FluentForm>
	);
}

export function useWorkZoneForm() {
	// TODO hook name should be reviewed

	const context = useFormContext();

	const ownContext: any = useContext(FormContext);

	return {
		...context,
		...ownContext
	};
}
