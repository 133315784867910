export function GetConfigFromStorage(name: string) {
	const item = localStorage.getItem(name);
	return item ? JSON.parse(item) : null;
}

export function SaveConfigToStorage(name: string, config: any) {
	localStorage.setItem(name, JSON.stringify(config));
}

export function RemoveConfigFromStorage(name: string) {
	localStorage.removeItem(name);
}
