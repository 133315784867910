import { ComponentDesignProp, Skeleton } from '@fluentui/react-northstar';
import { useMemo } from 'react';
import { ColumnLayout } from './utility/getDefaultLayout';

type TableBodyCell = {
	name: string;
	layout: ColumnLayout;
};

type ExtendedComponentDesignProp = ComponentDesignProp & {
	justifyContent?: string;
};

function CellLoading({ column }: { column: TableBodyCell }) {
	const design = useMemo(() => {
		const { layout } = column;

		const designProp: ExtendedComponentDesignProp = {
			paddingRight: '8px',
			paddingLeft: '8px'
		};

		if (typeof layout.width === 'number') {
			designProp.maxWidth = `${layout.width}px`;
			designProp.minWidth = `${layout.width}px`;
		}

		return designProp;
	}, [column]);

	return (
		<Skeleton animation="wave" design={design}>
			<Skeleton.Line />
		</Skeleton>
	);
}

export default CellLoading;
