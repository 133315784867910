import { ListLayout } from 'components/Table/utility/getDefaultLayout';
import Row from 'components/Table/Row';
import { TableSourceOptions } from 'components/Table/TableSource';
import convertPageDataToRows from 'components/Table/utility/convertPageDataToRows';
import { TableRowData } from 'types';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { InfiniteLoaderProps, ListProps, TableProps } from 'react-virtualized';

import { InfiniteSource, useInfiniteSource } from './';

type TableContext = {
	rows: TableRowData[];
	count: number;
	source: InfiniteSource;
	isLoading: boolean;
	isRowLoaded: InfiniteLoaderProps['isRowLoaded'];
	loadMoreRows: InfiniteLoaderProps['loadMoreRows'];
	rowGetter: TableProps['rowGetter'];
	rowRenderer: ListProps['rowRenderer'];
	getHeaderWidth: (width: number) => number;
	onScrollbarPresenceChange: TableProps['onScrollbarPresenceChange'];
};

export default function useTable(
	options: TableSourceOptions,
	customLayout?: { [collection: string]: ListLayout }
): TableContext {
	const [rows, setRows] = useState<TableRowData[]>([]);
	const usedSource = useInfiniteSource(options, customLayout);
	const { data, source, isLoading, isFetching, count, fetchNextPage } =
		usedSource;
	const [scrollBarSize, setScrollbarSize] = useState(0);
	const [shouldDisplayLoader, updateShouldDispayLoader] = useState(true);

	const isRowLoaded = useCallback(
		({ index }: { index: number }) => typeof rows[index] !== 'undefined',
		[rows]
	);

	const loadMoreRows = useCallback(
		({
			startIndex,
			stopIndex
		}: {
			startIndex: number;
			stopIndex: number;
		}) => {
			if (!rows[startIndex] || !rows[stopIndex]) {
				return fetchNextPage();
			} else {
				return Promise.resolve();
			}
		},
		[fetchNextPage, rows]
	);

	const rowGetter = useCallback(
		({ index }: { index: number }) => rows[index],
		[rows]
	);

	const rowRenderer: ListProps['rowRenderer'] = useCallback(
		({ index, style }) => {
			const row = rows[index];

			return (
				<Row
					source={usedSource}
					key={row?.key || index}
					row={row}
					index={index}
					style={style}
				/>
			);
		},
		[rows, usedSource]
	);

	const onScrollbarPresenceChange = useCallback(({ vertical, size }) => {
		setScrollbarSize(vertical ? size : 0);
	}, []);

	const getHeaderWidth = useCallback(
		(width: number) =>
			width - (scrollBarSize ? scrollBarSize - 1 : scrollBarSize),
		[scrollBarSize]
	);

	const layout = useMemo(() => source.getLayout(), [source]);

	useEffect(() => {
		if (!isLoading && !isFetching) {
			if (data) {
				setRows(convertPageDataToRows(layout, data));
			}

			updateShouldDispayLoader(!data);
		} else {
			if (isLoading && isFetching) {
				updateShouldDispayLoader(true);
			}
		}
	}, [data, layout, isLoading, isFetching]);

	return {
		rows,
		count,
		source: usedSource,
		isLoading: shouldDisplayLoader,
		isRowLoaded,
		loadMoreRows,
		rowGetter,
		rowRenderer,
		getHeaderWidth,
		onScrollbarPresenceChange
	};
}
