import {
	TriangleDownIcon,
	TriangleUpIcon
} from '@fluentui/react-icons-northstar';
import {
	ComponentDesignProp,
	Flex,
	gridHeaderCellBehavior,
	Table
} from '@fluentui/react-northstar';
import { useTabSettings } from 'components/App/TabSettings';
import { ColumnSettingsContext } from 'components/Tab';
import {
	Dispatch,
	SetStateAction,
	useContext,
	useState,
	useRef,
	useMemo,
	useCallback
} from 'react';
import GetDefaultLayout, {
	ColumnSort,
	ColumnLayout
} from './utility/getDefaultLayout';
import {
	GetConfigFromStorage,
	SaveConfigToStorage
} from './LocalStorageHandler';
import ResizeColumnIcon from './ResizeColumnIcon';
import type { InfiniteSource } from 'hook';

type ExtendedComponentDesignProp = ComponentDesignProp & {
	justifyContent?: string;
};

export default function HeaderCell({
	name,
	sortDirection,
	column,
	source,
	onSort,
	dataColumIndex,
	onRerenderTable,
	isResizing,
	setIsResizing
}: {
	name: string;
	sortDirection?: keyof typeof ColumnSort;
	column: ColumnLayout;
	source: InfiniteSource;
	onSort?: (name: string, order: keyof typeof ColumnSort) => void;
	dataColumIndex: number;
	onRerenderTable: () => void;
	isResizing: boolean;
	setIsResizing: Dispatch<SetStateAction<boolean>>;
}) {
	const setColumnSettings = useContext(ColumnSettingsContext);
	const { tabId } = useTabSettings();
	const [isHover, setIsHover] = useState(false);

	const { source: tableSource } = source;

	const currentRef = useRef<HTMLDivElement>(null);
	const headerWidth =
		typeof column?.width === 'number'
			? column?.width
			: currentRef.current?.offsetWidth;

	const design = useMemo(() => {
		const { width } = column;

		const designProp: ExtendedComponentDesignProp = {};

		if (typeof width === 'number') {
			designProp.maxWidth = `${width}px`;
			designProp.minWidth = `${width}px`;
		}

		if (width === 'fit-content') {
			designProp.maxWidth = 'fit-content';
			designProp.minWidth = 'fit-content';
		}

		if (column.align) {
			designProp.justifyContent = column.align;
			if (column.align === 'right') {
				designProp.position = 'absolute';
				designProp.right = '0px';
			}
		}

		return designProp;
	}, [column]);

	const resizeHandler = useCallback(
		(deltaX: number) => {
			if (typeof headerWidth === 'number') {
				const layout =
					GetConfigFromStorage(tabId) ?? GetDefaultLayout();
				const updatedLayout = { ...layout };
				if (
					deltaX < 0 &&
					(Math.abs(deltaX) >= headerWidth + 50 ||
						headerWidth + deltaX < 50)
				) {
					updatedLayout[tableSource.collection].columns[
						name
					].width = 50;
					SaveConfigToStorage(tabId, updatedLayout);
					setColumnSettings(GetConfigFromStorage(tabId));
					onRerenderTable();
					return;
				}
				updatedLayout[tableSource.collection].columns[name].width =
					headerWidth + deltaX;
				SaveConfigToStorage(tabId, updatedLayout);
				setColumnSettings(GetConfigFromStorage(tabId));
				onRerenderTable();
			}
		},
		[
			headerWidth,
			name,
			onRerenderTable,
			setColumnSettings,
			tabId,
			tableSource.collection
		]
	);

	const title =
		typeof column.label === 'string' ? column.label : column.label?.title;

	const content =
		typeof column.label === 'string'
			? column.label
			: column.label?.value(source);

	const getDisplayCriterion = useCallback(
		() => (isResizing ? false : isHover),
		[isHover, isResizing]
	);

	const sortHeaderRenderer = useCallback(() => {
		const sortableHeaderContent = (
			<SortableHeaderCell
				name={name}
				sortDirection={sortDirection}
				content={content}
				onSort={onSort}
			/>
		);

		if (!column.isDefault || typeof column.width === 'number') {
			return (
				<Flex
					vAlign="center"
					style={{
						justifyContent: 'space-between',
						width: '100%'
					}}
				>
					{sortableHeaderContent}
					<ResizeColumnIcon
						resizeHandler={resizeHandler}
						setIsResizing={setIsResizing}
						style={{
							visibility: getDisplayCriterion()
								? 'visible'
								: 'hidden',
							position: 'absolute',
							right: 10
						}}
						columnClassName={`column-index-${dataColumIndex}`}
					/>
				</Flex>
			);
		} else {
			return sortableHeaderContent;
		}
	}, [
		column.isDefault,
		column.width,
		content,
		dataColumIndex,
		getDisplayCriterion,
		name,
		onSort,
		resizeHandler,
		setIsResizing,
		sortDirection
	]);

	return (
		<Table.Cell
			className={`column-index-${dataColumIndex}`}
			truncateContent
			content={column.exists !== false ? sortHeaderRenderer() : content}
			title={title}
			accessibility={gridHeaderCellBehavior}
			design={design}
			style={{
				width: '100%',
				backgroundColor: 'transparent'
			}}
			onMouseEnter={() => {
				setIsHover(true);
			}}
			onMouseLeave={() => {
				setTimeout(() => setIsHover(false), 50);
			}}
			ref={currentRef}
		/>
	);
}

function SortableHeaderCell({
	name,
	sortDirection,
	content,
	onSort
}: {
	name: string;
	sortDirection?: keyof typeof ColumnSort;
	content: React.ReactNode;
	onSort?: (column: string, order: keyof typeof ColumnSort) => void;
}) {
	const [activeClassName, setClassName] = useState(['']);

	const toggleSortDirection = useCallback(() => {
		if (onSort) {
			onSort(name, sortDirection === ColumnSort.desc ? 'asc' : 'desc');
		}
	}, [sortDirection, name, onSort]);

	const showSortIcon = useCallback(() => {
		setClassName(['ui-sortable-active']);
	}, []);

	const hideSortIcon = useCallback(() => {
		setClassName(['']);
	}, []);

	const className = ['ui-sortable', ...activeClassName].filter(Boolean);

	if (sortDirection) {
		className.push('ui-sortable-sorted');
	}

	return (
		<Flex
			className={className.join(' ')}
			onClick={toggleSortDirection}
			onMouseOver={showSortIcon}
			onMouseOut={hideSortIcon}
		>
			{content}
			{sortDirection === ColumnSort.desc ? (
				<TriangleDownIcon />
			) : (
				<TriangleUpIcon />
			)}
		</Flex>
	);
}
