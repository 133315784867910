import { MsalAuthenticationResult } from '@azure/msal-react';

import { Alert } from '@fluentui/react-northstar';
import { ErrorIcon } from '@fluentui/react-icons-northstar';

export default function ErrorComponent({
	error
}:
	| MsalAuthenticationResult
	| { error: string }
	| { error: { errorCode: string; errorMessage: string } }) {
	//throw new Error(`${error?.errorCode}: ${error?.errorMessage}`);

	return (
		<Alert
			icon={<ErrorIcon />}
			content={
				typeof error !== 'string' && error?.errorCode
					? `${error.errorCode}: ${error?.errorMessage}`
					: error
			}
		/>
	);
}
